.plus_minus_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 6px;

    .minus,
    .plus {
        width: 30px;
        height: 30px;
        border-radius: 8px;
        background-color: #f9a109;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        color: #ffffff;
        cursor: pointer;
    }
}
