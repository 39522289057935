.blogs_container {
    // margin-top: 50px;
    padding-inline: 5rem;
    // padding-top: 50px;
    // padding-bottom: 50px;
    // padding-left: 5rem;
    // padding-right: 5rem;
    .blogs_row {
        margin-top: 30px;
        .blog_column {
            margin-bottom: 30px;

            // .blogs_slider {
            //     .slick-arrow {
            //         &::before {
            //             color: #91743e;
            //         }
            //     }
            //     .slick-list {
            //         overflow: hidden;
            //         .slick-track {
            //             display: flex;
            //             gap: 50px;

            //             .slick-slide {
            //                 min-height: 100% !important;
            //                 display: flex !important;
            //                 height: inherit;
            //             }
            //         }
            //     }
            // }

            .blog_box {
                transition: all 0.5s ease 0s;
                overflow: hidden;
                position: relative;
                text-align: left;
                // background: #fff;
                background: rgba(145, 116, 62, 0.7);
                display: flex !important;
                flex-direction: column;
                justify-content: flex-start;
                gap: 20px;
                border-radius: 15px;
                box-shadow:
                    0 4px 8px 0 rgba(0, 0, 0, 0.2),
                    0 6px 20px 0 rgba(0, 0, 0, 0.19);
                margin-bottom: 15px;

                .blog_image {
                    position: relative;
                    img {
                        width: 100%;
                        height: 250px;
                        border-radius: 15px 15px 0 0;
                    }
                    .blog_author {
                        position: absolute;
                        bottom: 0;
                        right: 0;
                        // background: #6d4e15db;
                        background: rgba(15, 15, 15, 0.7);
                        color: #fff;
                        padding: 7px;
                        .author_position {
                            font-size: 14px;
                            font-weight: 400;
                            color: #fff;
                            width: 100%;
                            float: right;
                            text-align: right;
                            font-style: italic;
                        }
                    }
                }
                .blog_content {
                    padding: 0 15px 15px;
                    display: flex;
                    flex-direction: column;
                    justify-content: stretch;
                    height: 100%;
                    position: relative;
                }
                .blog_footer {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    padding-right: 15px;
                    padding-bottom: 15px;
                    position: relative;

                    .read_more {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        width: -moz-fit-content;
                        // background: #91743e;
                        background: rgba(15, 15, 15, 0.7);
                        color: #fff;
                        padding: 4px 8px;
                        border-radius: 8px;
                        cursor: pointer;
                        gap: 4px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .blogs_container {
        padding-inline: 1rem;
    }
}
