.submit_review_button {
    padding: 8px 12px;
    border: 1px solid #91743e;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
    background-color: #91743e;
    color: #ffffff;

    // &:hover {
    //     background-color: #ffffff;
    //     color: #13c519;
    // }
}
