html {
    scroll-behavior: smooth;
}

body {
    scroll-behavior: smooth;
}

.highlight_green {
    color: #91743e;
    font-size: 22px;
}
