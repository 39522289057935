.my_orders_screen {
    padding: 20px 120px;

    .my_order_title {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 20px;
    }
    .my_order_list_wrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 20px;

        .my_order_card {
            border: 1px solid #ccc;
            border-radius: 20px;

            .my_order_card_header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 10px 15px;
                background-color: #a5e7b4;
                border-top-left-radius: 20px;
                border-top-right-radius: 20px;

                .my_order_key {
                    font-size: 16px;
                    font-weight: 600;
                }
                .my_order_value {
                    font-size: 14px;
                    font-weight: 400;
                }

                .track_order_button {
                    padding: 8px 12px;
                    border: 1px solid #ccc;
                    border-radius: 10px;
                    font-size: 14px;
                    font-weight: 500;
                    cursor: pointer;
                    transition: all 0.3s ease;

                    background-color: #ffffff;
                    color: #13c519;

                    &:hover {
                        background-color: #13c519;
                        color: #ffffff;
                    }
                }
            }

            .my_order_card_body {
                padding: 15px;
                display: flex;
                flex-direction: column;
                gap: 10px;

                .order_details {
                    display: flex;
                    flex-direction: row;
                    align-items: flex-start;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    gap: 10px;

                    .product_details_block {
                        display: flex;
                        flex-direction: row;
                        align-items: flex-start;
                        width: 100%;
                        justify-content: flex-start;

                        .order_image {
                            width: 100px;
                            height: 100px;

                            img {
                                // width: 100%;
                                // height: 100%;

                                width: 100px;
                                height: 100px;
                                border-radius: 10px;
                                background-color: #ccc;
                                margin-right: 20px;
                                cursor: pointer;
                            }
                        }

                        .order_info {
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            align-items: flex-start;
                            gap: 10px;
                            padding: 0 0 0 20px;
                            width: 100%;
                            flex-wrap: wrap;

                            .order_name {
                                font-size: 18px;
                                font-weight: 600;
                            }
                            .order_price {
                                font-size: 16px;
                                font-weight: 500;
                                display: flex;
                                align-items: center;
                                gap: 10px;

                                .actual_price {
                                    text-decoration: line-through;
                                    color: #999;
                                }
                            }
                        }
                    }
                    .product_review_block {
                        display: flex;
                        // flex-direction: column;
                        align-items: flex-start;
                        .review_button {
                            padding: 8px 12px;
                            border: 1px solid #91743e;
                            border-radius: 10px;
                            font-size: 14px;
                            font-weight: 500;
                            cursor: pointer;
                            transition: all 0.3s ease;
                            background-color: #ffffff;
                            color: #91743e;
                            outline: none;

                            &:hover {
                                background-color: #91743e;
                                color: #ffffff;
                            }
                        }
                    }
                }

                .order_buttons_wrapper {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 10px;
                    margin-top: 10px;

                    .order_button {
                        padding: 8px 12px;
                        border: 1px solid #91743e;
                        border-radius: 10px;
                        font-size: 14px;
                        font-weight: 500;
                        cursor: pointer;
                        transition: all 0.3s ease;
                        background-color: #91743e;
                        color: #ffffff;

                        &:hover {
                            background-color: #ffffff;
                            color: #91743e;
                        }
                    }

                    .order_cancel_button {
                        padding: 8px 12px;
                        border: 1px solid #ccc;
                        border-radius: 10px;
                        font-size: 14px;
                        font-weight: 500;
                        cursor: pointer;
                        transition: all 0.3s ease;
                        background-color: #ff0000;
                        color: #ffffff;

                        &:hover {
                            background-color: #ffffff;
                            color: #ff0000;
                        }
                    }
                }
            }
        }
    }

    .no_orders {
        font-size: 22px;
        font-weight: 600;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 10px;
        width: 100%;
        height: 50vh;

        .shop_now_button {
            padding: 8px 12px;
            border: 1px solid #ccc;
            border-radius: 10px;
            font-size: 20px;
            font-weight: 500;
            cursor: pointer;
            transition: all 0.3s ease;
            background-color: #13c519;
            color: #ffffff;
            width: fit-content;

            &:hover {
                background-color: #ffffff;
                color: #13c519;
            }
        }
    }
}

@media (max-width: 768px) {
    .my_orders_screen {
        padding: 10px;
        .my_order_title {
            font-size: 20px;
            margin-bottom: 10px;
        }

        .my_order_list_wrapper {
            .my_order_card {
                .my_order_card_header {
                    flex-wrap: wrap;
                    gap: 5px;

                    .item_wrapper {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        gap: 5px;
                    }
                    .my_order_key {
                        font-size: 14px;
                    }
                    .my_order_value {
                        font-size: 12px;
                    }

                    .track_order_button {
                        font-size: 12px;
                    }
                }

                .my_order_card_body {
                    .order_details {
                        .product_details_block {
                            .order_image {
                                width: 80px;
                                height: 80px;
                                img {
                                    width: 80px;
                                    height: 80px;
                                }
                            }

                            .order_info {
                                // gap: 0;
                                .order_name {
                                    font-size: 16px;
                                }
                                .order_price {
                                    font-size: 14px;
                                }
                            }
                        }
                    }

                    .order_buttons_wrapper {
                        .order_button {
                            font-size: 12px;
                        }

                        .order_cancel_button {
                            font-size: 12px;
                        }
                    }
                }
            }
        }

        .no_orders {
            font-size: 18px;
            .shop_now_button {
                font-size: 18px;
            }
        }
    }
}
