.product-details-page {
    padding: 20px 40px;

    .productname_wrapper {
        display: flex;
        justify-content: start;
        align-items: center;
        gap: 20px;

        .back_icon {
            font-size: 30px;
            cursor: pointer;
        }

        .product_name {
            font-size: 24px;
            font-family: "rockwell";
            padding-top: 10px;
        }
    }

    .product_details_wrapper {
        margin-top: 10px;
        display: flex;
        gap: 20px;
        .details_sec1 {
            width: 25%;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .product_image {
                width: 100%;
                height: 100%;
                border-radius: 8px;
                img {
                    width: 100% !important;
                    height: 100%;
                    border-radius: 8px;
                    // dont apply transition on image remove it from here
                    transition: transform 0.3s ease;

                    border: 1px solid #eee;
                    background-color: var(--white-color);
                    position: relative;
                    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
                    cursor: pointer;
                }
            }
            .product_price {
                font-size: 20px;
                font-family: "rockwell";
                margin-top: 15px;
                background-color: #a5e7b4;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 8px;
                padding: 5px 10px;
            }
        }
        .details_sec2 {
            width: 50%;
            .product_short_review {
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-direction: row;
                flex-wrap: nowrap;
                color: #0f1111;
                .product_path {
                    color: #ac8d4f;
                    font-size: 16px;
                    font-family: "rockwell";
                }
                .average_rating {
                    display: flex;
                    align-items: center;
                    position: relative;
                    // gap: 8px;
                    font-weight: 700;
                    flex-wrap: wrap;

                    .average_rating_stars {
                        display: flex;
                        margin-right: 8px;
                    }
                }
            }
            .product_images {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                margin-top: 20px;
                .slide-to-left,
                .slide-to-right {
                    cursor: pointer;
                    padding: 10px;
                }
                .product_images_wrapper {
                    overflow: hidden;
                    white-space: nowrap;
                    width: 100%;
                    .products-list {
                        display: flex;
                        transition: transform 0.3s ease;
                        padding: 10px 5px;
                        .product_image {
                            flex: 0 0 auto; /* Adjust as necessary */
                            margin-right: 10px; /* Adjust as necessary */
                            transition: transform 0.3s ease;

                            border: 1px solid #eee;
                            background-color: var(--white-color);
                            position: relative;
                            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
                            cursor: pointer;
                            img {
                                min-width: 150px;
                                border-radius: 8px;
                                cursor: pointer;
                                height: 150px;
                            }
                        }
                    }
                }
            }

            .key_features_title {
                text-align: center;
                font-family: "rockwell";
                margin-top: 30px;
                font-size: 20px;
            }
            .key_features {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 10px;
                gap: 10px;

                .tag {
                    width: 70px;
                    height: 70px;
                    border-radius: 50%;
                    background-color: #a5e7b4;
                }
            }
        }
        .details_sec3 {
            width: 25%;
            .order_summary_wrapper {
                margin-top: 20px;
                // border: 1px solid #ccc;
                border-radius: 20px;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

                .free_delivery_banner {
                    background-color: #13c519;
                    color: #ffffff;
                    padding: 10px;
                    border-radius: 20px 20px 0 0;
                    font-size: 14px;
                    font-weight: 500;
                    display: flex;
                    justify-content: center;

                    .celebration_icon {
                        font-size: 20px;
                        margin-right: 10px;
                    }
                    .banner_text {
                        font-size: 20px;
                        font-weight: 500;
                    }
                }

                .payment_details {
                    padding: 10px 15px 15px;

                    .payment_title {
                        font-size: 18px;
                        font-weight: 600;
                        margin-bottom: 10px;
                    }

                    .horizontal_line {
                        margin: 10px 0;
                    }

                    .payment_details_row {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;

                        .payment_details_row_title {
                            font-size: 16px;
                        }
                        .payment_details_row_value {
                            font-size: 16px;
                            font-weight: 500;
                        }
                    }
                }

                .pay_and_cart_btn {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    margin-top: 20px;
                    gap: 20px;

                    .pay_now_btn {
                        width: 100%;
                        padding: 10px;
                        border-radius: 10px;
                        background-color: #13c519;
                        color: #ffffff;
                        font-size: 16px;
                        font-weight: 500;
                        text-align: center;
                        border: none;
                    }
                    .add_to_cart_btn {
                        width: 100%;
                        padding: 10px;
                        border-radius: 10px;
                        background-color: #f9a109;
                        color: #ffffff;
                        font-size: 16px;
                        font-weight: 500;
                        text-align: center;
                        border: none;
                    }
                }
            }
        }
    }

    .product_description {
        margin-top: 2em;
        .product_details_tab {
            &:focus {
                outline: none;
            }
        }
        .key_points_wrapper {
            background: #e0c391;
            padding: 10px;
            margin-top: 20px;
            border-radius: 20px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .key_points_div {
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                padding: 0 15px;

                .key_points {
                    .key_points_title {
                        font-size: 20px;
                        font-family: "rockwell";
                        // text-align: center;
                        margin-bottom: 10px;
                    }

                    .list_of_points {
                        ul {
                            // list-style: none;
                            padding: 0px;
                            margin: 0px;
                            li {
                                font-size: 16px;
                                font-family: "rockwell";
                                margin-bottom: 5px;
                            }
                        }
                    }
                }

                .verticle_line {
                    border: 1.5px solid #000;
                    position: relative;
                    left: 10px;
                    height: 90%;
                }
            }
        }

        .product_reviews_content {
            .review_heading {
                font-size: 20px;
                margin-bottom: 10px;
                font-weight: 700;
                line-height: 20px;
                margin-bottom: 30px;
            }
            .review_container {
                // display: flex;
                // flex-direction: row;
                // align-items: center;
                // justify-content: stretch;
                // flex-wrap: nowrap;
                // width: 100%;
                // gap: 40px;
                color: #0f1111 !important;
                line-height: 20px;
                .total_reviews_block {
                    display: flex;
                    flex-direction: column;
                    position: relative;
                    gap: 10px;
                    // width: 30%;

                    .average_rating {
                        display: flex;
                        justify-content: flex-start;
                        flex-direction: row;
                        align-items: center;
                        line-height: 20px;
                        width: 100%;
                        flex-wrap: wrap;
                        .average_rating_stars {
                            display: flex;
                            margin-right: 8px;
                        }
                        .out-of-rating {
                            font-size: 16px;
                            font-weight: 600;
                            // margin-left: 10px;
                            vertical-align: middle;
                        }
                    }
                    .review_count {
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 20px;
                    }
                    .total_stars_block {
                        display: flex;
                        flex-direction: column;
                        gap: 10px;
                        .star_rating_block {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            flex-direction: row;
                            gap: 8px;
                            .star_bar {
                                flex: 1;
                                height: 15px;
                                background-color: #efefef; //#e0e0e0;
                                position: relative;
                                border-radius: 4px;
                                top: 2px;

                                .star_fill {
                                    height: 100%;
                                    background-color: #d2a34c; //#faaf00;
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    transition: width 0.5s ease-in-out;
                                    border-radius: 4px;
                                }
                            }
                        }
                    }
                    .review_divider {
                        margin-block: 10px;
                    }
                    .add_review_block {
                        display: flex;
                        flex-direction: column;
                        gap: 10px;
                        align-items: flex-start;

                        .sub_heading {
                            font-size: 18px;
                            // margin-top: 10px;
                        }

                        .add_review_btn {
                            width: 100%;
                            text-align: center;
                            font-size: 14px;
                            font-weight: 500;
                            padding: 8px;
                            border: 1px solid #91743e;
                            border-radius: 10px;
                            color: #ffffff;
                            cursor: pointer;
                            background: #91743e;
                            outline: none;
                        }
                    }
                }
                .reviews_block {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 20px;
                    margin-left: 40px;
                    // width: 70%;
                    .review_block {
                        display: flex;
                        flex-direction: column;
                        gap: 10px;
                        align-items: flex-start;
                        .review_header {
                            .reviewer_name {
                                display: flex;
                                align-items: center;
                                flex-direction: row;
                                gap: 4px;
                                position: relative;
                                .icon_container {
                                    color: #7f7f7f;
                                    display: flex;
                                }
                                .customer_name {
                                    font-size: 14px;
                                    font-weight: 700;
                                    color: #0f1111;
                                }
                            }
                        }
                        .review_rating {
                            display: flex;
                            align-items: center;
                            flex-direction: row;
                            gap: 8px;
                            flex-wrap: wrap;
                            .rating_stars {
                                display: flex;
                                align-items: center;
                            }
                            .review_headline {
                                font-weight: 600;
                                font-size: 16px;
                                line-height: 20px;
                            }
                        }
                    }
                }
            }
        }
    }

    .conservation_text {
        font-size: 36px;
        color: #000;
        font-family: "rock";
        text-align: center;
        margin-top: 10px;
    }

    .plus_minus_wrapper {
        gap: 6px !important;
    }
}

// for mobile

@media (max-width: 768px) {
    .product-details-page {
        padding: 10px 15px;
        .productname_wrapper {
            font-size: 20px;
            gap: 10px;
            display: flex;
            justify-content: start;
            align-items: center;

            .back_icon {
                font-size: 25px;
            }
            .product_name {
                font-size: 20px;
                padding: 4px 0 0 0;
            }
        }
        .product_details_wrapper {
            display: flex;
            flex-direction: column;
            gap: 0px;
            margin-top: 0px;
            .details_sec1 {
                width: 100%;
                padding: 10px;

                .product_image {
                    // width: 100%;
                    // height: 100%;
                    // border-radius: 8px;
                    // text-align: center;
                    flex: 0 0 auto; /* Adjust as necessary */
                    margin-right: 10px; /* Adjust as necessary */
                    img {
                        min-width: 150px;
                        // width: 100%;
                        // height: 100%;
                        border-radius: 8px;
                        cursor: pointer;
                    }
                }
            }
            .details_sec2 {
                width: 100%;

                .key_features_title {
                    margin-top: 15px;
                }

                .key_features .tag {
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    background-color: #a5e7b4;
                }

                .product_images {
                    .product_images_wrapper {
                        overflow: hidden;
                        white-space: nowrap;
                        width: 100%;
                        .products-list {
                            display: flex;
                            transition: transform 0.3s ease;
                            .product_image {
                                height: 100px !important;
                                width: 100px;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
            .details_sec3 {
                width: 100%;

                .plus_minus_wrapper {
                    gap: 10px;
                }
            }
        }

        .key_points_wrapper {
            background: #e0c391;
            padding: 10px;
            margin-top: 20px;
            border-radius: 20px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 20px;
        }

        .conservation_text {
            font-size: 24px;
            margin-top: 20px;
            font-family: "rockwell";
        }
    }
}

@media (max-width: 575.5px) {
    // .product_reviews_content {
    //     .review_heading {
    //         .review_container {
    .reviews_block {
        margin-left: 0px !important;
        margin-top: 40px;
    }
    //         }
    //     }
    // }
}
