.customized_order_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    .gallary_block {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        padding: 0;
        margin: 0;
        gap: 20px;
        .image_block {
            width: 28%;
            height: auto;
            img {
                width: 100%;
                height: 100%;
                border-radius: 8px;
            }
        }
    }
}
