.categories_listing_section {
    margin-bottom: 200px;
    padding-left: 3rem;
    padding-right: 3rem;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    .category_selection_wrapper {
        flex: 1;
        .cat_tab_wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;
            flex-direction: column;
            margin-top: 30px;

            .cat_box {
                border: none;
                background-color: rgba(0, 0, 0, 0);
                display: block;
                text-align: left;
                padding: 24px 20px 24px 110px;
                margin: 10px 0 10px 0;
                position: relative;
                width: auto;
                // max-width: 330px;
                border-radius: 9999px;
                transition: all ease 0.4s;
                text-align: start;
                cursor: pointer;
                width: 85%;
            }
            .cat_box:before {
                content: "";
                position: absolute;
                top: 10px;
                right: 0;
                bottom: 10px;
                left: 30px;
                border: 2px solid var(--stroke-color);
                border-radius: inherit;
                transition: all ease 0.4s;
            }
            // .cat_box:after {
            //     content: "";
            //     position: absolute;
            //     right: -9px;
            //     top: 50%;
            //     border-top: 10px solid rgba(0, 0, 0, 0);
            //     border-bottom: 10px solid rgba(0, 0, 0, 0);
            //     border-left: 10px solid var(--stroke-color);
            //     transform: translateY(-50%);
            //     transition: all ease 0.4s;
            //     opacity: 0;
            //     visibility: hidden;
            // }

            .cat_img {
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                width: 90px;
                height: 90px;
                border-radius: 50%;
            }
            .cat_title {
                display: block;
                color: #111;
                font-size: 16px;
                font-weight: 700;
                transition: all ease 0.4s;
                position: relative;
                z-index: 2;
                line-height: 18px;
            }
            .cat_text {
                font-size: 14px;
                color: #666;
                position: relative;
                z-index: 2;
                transition: all ease 0.4s;
            }

            .cat_box {
                &.active,
                &:hover {
                    .cat_title {
                        color: #fff;
                    }
                    .cat_text {
                        color: #fff;
                    }

                    &::after {
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }

            .cat_box:nth-child(1) {
                --stroke-color: #91743e;

                &.active::before,
                &:hover::before {
                    background-color: #91743e;
                }
            }
            .cat_box:nth-child(2) {
                --stroke-color: #049952;
                &.active::before,
                &:hover::before {
                    background-color: #049952;
                }
            }

            .cat_box:nth-child(3) {
                --stroke-color: #37b7ff;
                &.active::before,
                &:hover::before {
                    background-color: #37b7ff;
                }
            }

            .cat_box:nth-child(4) {
                --stroke-color: #965a3e;
                &.active::before,
                &:hover::before {
                    background-color: #965a3e;
                }
            }

            .cat_box:nth-child(5) {
                --stroke-color: #f5ae9b;

                &.active::before,
                &:hover::before {
                    background-color: #f5ae9b;
                }
            }
            .cat_box:nth-child(6) {
                --stroke-color: #2c5b50;

                &.active::before,
                &:hover::before {
                    background-color: #2c5b50;
                }
            }
        }
    }

    .cat_content_wrapper {
        flex: 3;
        margin-top: 30px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 2rem;
        flex-wrap: wrap;
        margin-left: 3rem;

        .product_card {
            border: 1px solid #eee;
            // margin-bottom: 30px;
            background-color: var(--white-color);
            position: relative;
            width: max-content;
            padding: 10px;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            cursor: pointer;

            &:hover {
                transform: scale(1.1);
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
                animation: all 0.1s ease !important;
            }

            .product_img {
                width: 215px;
                height: 215px;
                text-align: center;
                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .product_details {
                .product_rating_box {
                    font-size: 16px;
                    font-weight: 600;
                    color: #d57369;
                    text-align: center;
                    margin-top: 10px;
                }

                .product_title {
                    font-size: 16px;
                    font-weight: 600;
                    margin: 0 0 2px 0;
                    color: #000;
                    width: 200px;
                    text-align: center;
                    margin-top: 10px;
                    height: 50px;
                }

                .product_bottom_wrapper {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .product_price {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        margin-top: 10px;
                        gap: 10px;

                        .actual_price {
                            font-size: 18px;
                            font-weight: 600;
                            color: #000;
                        }

                        .mrp_price {
                            text-decoration: line-through;
                            color: #666;
                            font-size: 16px;
                            font-weight: 400;
                        }
                    }

                    .buy_now_btn {
                        color: #91743e;
                        padding: 5px 10px;
                        border-radius: 5px;
                        font-weight: 600;
                        text-transform: uppercase;
                        cursor: pointer;
                        transition: all 0.3s ease;
                        outline: none;
                        border: 1px solid #91743e;
                        background-color: #fff;
                        font-size: 14px;

                        &:hover {
                            color: #fff;
                            background-color: #91743e;
                        }
                    }
                }
            }
        }
    }

    .view_all_products {
        margin-top: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;

        .view_all_btn {
            background-color: #fff;
            color: #91743e;
            padding: 10px 20px;
            border-radius: 5px;
            font-weight: 600;
            text-transform: uppercase;
            cursor: pointer;
            transition: all 0.3s ease;
            border: 2px solid #91743e;

            &:hover {
                background-color: #91743e;
                color: #fff;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .categories_listing_section {
        padding-left: 1rem;
        padding-right: 1rem;
        flex-direction: column;
        width: 100%;

        .category_selection_wrapper {
            overflow-x: auto;
            white-space: nowrap;
            width: -webkit-fill-available;

            .cat_tab_wrapper {
                flex-direction: row;
                gap: 10px;
                margin-top: 20px;
                display: inline-flex;
                justify-content: flex-start;

                .cat_box {
                    padding: 20px 20px 20px 86px;
                    width: auto;
                    display: inline-block;
                }
                .cat_img {
                    width: 80px;
                    height: 80px;
                }
            }
        }

        .cat_content_wrapper {
            margin-left: 0.5rem;
            overflow: scroll;
            flex-wrap: nowrap;
            width: -webkit-fill-available;
            padding-right: 20px;
            padding-bottom: 20px;
            gap: 1rem;

            .product_card {
                display: flex;
                flex-direction: column;
                width: 70%;

                &:hover {
                    transform: scale(1);
                    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
                    animation: none;
                }

                .product_img {
                    width: 200px;
                    height: 200px;
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }
}
