.home_wrapper {
    .first_section {
        position: relative;
        width: 100%;
        height: 700px;

        .first_backimg {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
        }

        .first_sec_logo {
            margin-top: 70px;
            img {
                width: 300px;
                height: 300px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0 auto;
            }
        }

        .first_sec_tagline {
            color: #000000;
            font-size: 30px;
            text-align: center;
            margin-top: 60px;
            font-family: "rock";
            background-color: #91743e;
            width: fit-content;
            padding: 5px 10px;
            border-radius: 10px;
        }
        .buynow_btn {
            width: max-content;
            padding: 10px 30px;
            background-color: #91743e;
            border: none;
            border-radius: 20px;
            color: #ffffff;
            position: absolute;
            bottom: 40px;
            right: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }
    }

    .second_section {
        position: relative;
        width: 100%;
        height: 750px;

        .second_backimg {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
        }

        .logo_wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: end;
            padding-top: 22px;
            margin-right: 22px;

            .logo {
                width: 150px;
                height: 150px;
                margin-top: 30px;
                margin-right: 3%;
            }
            .tag_line {
                font-size: 20px;
                color: #000000;
                text-align: center;
                margin-top: 20px;
                font-family: "rock";
                font-weight: 600;
            }
        }

        .our_why_wrapper {
            display: flex;
            justify-content: space-between;
            padding: 0 70px;
            margin-top: 150px;

            .our_why_text {
                font-size: 46px;
                width: 100%;
                color: #ffffff;
                font-family: "rock";
            }
            .our_why_desc {
                color: #ffffff;
                font-family: "rock";
                font-size: 35px;
            }
        }
    }

    .third_section {
        position: relative;
        width: 100%;
        height: 700px;

        .third_backimg {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
        }
        .what_we_offer_text {
            font-size: 40px;
            color: #ffffff;
            font-family: "rock";
            text-align: center;
            padding-top: 40px;
        }

        .products_wrapper {
            margin: 30px 6rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            gap: 50px;

            .product_card {
                display: flex;
                flex-direction: column;
                align-items: center;

                .product_card_img img {
                    width: 250px;
                    height: 250px;
                    border-radius: 10px;
                }

                .product_name {
                    font-size: 24px;
                    color: #a5e7b4;
                    font-family: "rock";
                    width: 270px;
                    text-align: center;
                    line-height: 1;
                    margin-top: 20px;
                }

                .product_text {
                    color: #ffffff;
                    margin-top: 10px;
                    font-family: "rock";
                    font-size: 16px;
                }

                .tags_wrapper {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-top: 10px;
                    gap: 10px;

                    .tag {
                        width: 60px;
                        height: 60px;
                        border-radius: 50%;
                        background-color: green;
                    }
                }

                .product_price {
                    font-size: 22px;
                    font-family: "rockwell_bold";
                    color: #000000;
                    margin-top: 15px;
                    background: #74a3d2;
                    padding: 0px 10px;
                }

                .product_buy_now_btn {
                    width: max-content;
                    padding: 10px 30px;
                    background-color: #91743e;
                    border: none;
                    border-radius: 20px;
                    color: #ffffff;
                    margin-top: 20px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                }
            }
        }
    }

    .fourth_section {
        position: relative;
        width: 100%;
        height: 700px;

        .fourth_backimg {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
        }

        .change_makers_text {
            font-size: 40px;
            color: #ffffff;
            font-family: "rock";
            text-align: center;
            padding-top: 40px;
        }

        .review_wrapper {
            margin: 30px 8rem;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 100px;

            .review_card {
                text-align: center;
                .review_card_img {
                    img {
                        width: 250px;
                        height: 250px;
                        border-radius: 20px;
                    }
                }
                .reviewer_name {
                    font-size: 28px;
                    color: #ffffff;
                    font-family: "rock";
                    text-align: center;
                    line-height: 1;
                    margin-top: 20px;
                }
                .review_text {
                    color: #000000;
                    margin-top: 20px;
                    font-family: "rock";
                    font-size: 18px;
                    text-align: center;
                }
                .star_wrapper {
                    display: flex;
                    justify-content: center;
                    // gap: 5px;
                    margin-top: 20px;
                    .star {
                        color: #a5e7b4;
                        font-size: 30px;
                    }
                }
            }
        }

        .conservation_text {
            font-size: 36px;
            color: #ffffff;
            font-family: "rock";
            text-align: center;
        }
    }

    .fifth_section {
        position: relative;
        width: 100%;
        height: 700px;

        .fifth_backimg {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
        }

        .sec_wrapper {
            display: flex;
            height: 100%;

            .sec_1 {
                width: 53%;
                height: 100%;
                position: relative;

                .visit_website_btn {
                    font-size: 16px;
                    color: #ffffff;
                    background: #000000;
                    position: absolute;
                    padding: 10px 15px;
                    font-family: "rock";
                    top: 145px;
                    right: 60px;
                    cursor: pointer;
                }

                .social_icon_wrapper {
                    position: absolute;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    bottom: 20px;

                    .social_icon {
                        font-size: 30px;
                        color: #ffffff;
                        margin: 0 10px;
                        cursor: pointer;
                    }
                }
            }
            .sec_2 {
                width: 47%;

                .description {
                    font-size: 33px;
                    color: #ffffff;
                    font-family: "rock";
                    text-align: left;
                    padding: 50px 10px;
                }
            }
        }
    }

    .sixth_section {
        position: relative;
        width: 100%;
        height: 700px;

        .sixth_backimg {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
        }

        .section_wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 100%;
            padding: 25px;

            .left_sec {
                width: 50%;
                height: 100%;
                position: relative;

                .get_in_touch_text {
                    font-size: 40px;
                    color: #ffffff;
                    font-family: "rock";
                    text-align: left;
                    padding-top: 40px;
                }

                .mailing_address_wrapper {
                    margin-top: 10px;
                    width: 400px;
                    margin-top: 20px;

                    .mailing_address_text {
                        font-size: 22px;
                        color: #ffffff;
                        font-family: "rock";
                        text-align: left;
                        text-transform: uppercase;
                    }

                    .mailing_address {
                        font-size: 18px;
                        color: #000;
                        font-family: "rock";
                        text-align: left;
                    }
                }

                .email_address_wrapper {
                    margin-top: 10px;
                    width: 400px;
                    margin-top: 40px;

                    .email_address_text {
                        font-size: 22px;
                        color: #ffffff;
                        font-family: "rock";
                        text-align: left;
                        text-transform: uppercase;
                    }

                    .email_address {
                        font-size: 18px;
                        color: #000;
                        font-family: "rock";
                        text-align: left;
                    }
                }

                .phone_number_wrapper {
                    margin-top: 10px;
                    width: 400px;
                    margin-top: 40px;

                    .phone_number_text {
                        font-size: 22px;
                        color: #ffffff;
                        font-family: "rock";
                        text-align: left;
                        text-transform: uppercase;
                    }

                    .phone_number {
                        font-size: 18px;
                        color: #000;
                        font-family: "rock";
                        text-align: left;
                    }
                }

                .website_develop_maintain {
                    font-size: 16px;
                    color: #000;
                    font-family: "rock";
                    text-align: left;
                    margin-top: 10px;
                    width: 300px;
                    margin-top: 40px;
                }
            }

            // <div className='right_sec'>
            //                         <div className='logo_wrapper'>
            //                             <img src={logo} className='logo' />
            //                         </div>
            //                         <div className='social_icon_wrapper'>
            //                             <FacebookIcon className='social_icon'
            //                                 onClick={() => goToSocialSite('facebook')}
            //                             />
            //                             <InstagramIcon className='social_icon'
            //                                 onClick={() => goToSocialSite('instagram')}
            //                             />
            //                             <LinkedInIcon className='social_icon' />

            //                         </div>

            //                     </div>

            .right_sec {
                // width: 20%;
                height: 100%;
                position: relative;
                padding-right: 50px;

                .logo_wrapper {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: end;
                    padding-top: 22px;
                    margin-right: 22px;

                    .logo {
                        width: 200px;
                        height: 200px;
                        margin-top: 30px;
                    }
                }

                .social_icon_wrapper {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-top: 40px;

                    .social_icon {
                        font-size: 30px;
                        color: #ffffff;
                        margin: 0 10px;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}

// media queries for screen width above 1000px and below 1200px

@media (max-width: 1200px) and (min-width: 900px) {
    .home_wrapper {
        .first_section {
            height: 600px;

            .first_sec_logo {
                margin-top: 50px;
                img {
                    width: 250px;
                    height: 250px;
                }
            }

            .first_sec_tagline {
                font-size: 25px;
                margin-top: 40px;
            }
            .buynow_btn {
                bottom: 30px;
                right: 20px;
            }
        }

        .second_section {
            height: 650px;

            .our_why_wrapper {
                margin-top: 100px;

                .our_why_text {
                    font-size: 40px;
                }
                .our_why_desc {
                    font-size: 30px;
                }
            }
        }

        .third_section {
            height: 600px;

            .what_we_offer_text {
                font-size: 30px;
                padding-top: 30px;
            }

            .products_wrapper {
                margin: 30px 4rem;

                .product_card {
                    .product_card_img img {
                        width: 200px;
                        height: 200px;
                    }

                    .product_name {
                        font-size: 20px;
                        width: 220px;
                        margin-top: 15px;
                    }

                    .product_text {
                        font-size: 14px;
                    }

                    .product_price {
                        font-size: 20px;
                    }

                    .product_buy_now_btn {
                        margin-top: 15px;
                    }
                }
            }
        }

        .fourth_section {
            height: 600px;

            .change_makers_text {
                font-size: 30px;
                padding-top: 30px;
            }

            .review_wrapper {
                margin: 30px 6rem;
                gap: 50px;

                .review_card {
                    .review_card_img {
                        img {
                            width: 200px;
                            height: 200px;
                        }
                    }
                    .reviewer_name {
                        font-size: 24px;
                        margin-top: 15px;
                    }
                    .review_text {
                        font-size: 16px;
                    }
                }
            }

            .conservation_text {
                font-size: 30px;
            }
        }

        .fifth_section {
            height: 600px;

            .sec_wrapper {
                .sec_1 {
                    .visit_website_btn {
                        font-size: 14px;
                        padding: 8px 12px;
                        top: 125px;
                        right: 50px;
                    }

                    .social_icon_wrapper {
                        .social_icon {
                            font-size: 25px;
                        }
                    }
                }

                .sec_2 {
                    .description {
                        font-size: 25px;
                        padding: 30px 10px;
                    }
                }
            }
        }
    }
}

.mobile_image_split_2 {
    width: 100vw;
    height: 50vh;
    background-size: 212%;
    background-repeat: no-repeat;
    background-color: #91743e;
    background-position: 100%;
}
