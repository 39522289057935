.blogs_viewer {
    padding-top: 50px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-inline: 5rem;
    gap: 20px;
    height: 75vh;
    overflow: hidden;

    .view_blog_container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        .blog_breadchrumb {
            font-weight: 500;
            font-size: 18px;
            line-height: 18px;
            font-style: normal;
            display: flex;
            flex-direction: row;
            align-items: center;

            svg {
                color: #91743e;
            }
            .breadchrumb_items {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 4px;

                .parent_menu {
                    cursor: pointer;
                    color: dodgerblue;
                }
                .separator {
                    color: dodgerblue;
                }
            }
        }
    }

    .pdf_container {
        width: 100%;
        height: 100%;
        overflow: auto;
        display: flex;
        justify-content: center;
        // height: calc(100% - 70px);
    }
}
