@font-face {
  font-family: 'rockwell';
  src: url(../src/assets/fonts/ROCK.TTF);
}
@font-face {
  font-family: 'rockwell_bold';
  src: url(../src/assets/fonts/Rockwell-Bold.ttf);
}

* {
  font-family: 'rockwell';
  /* user-select: none; */
}