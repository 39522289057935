// our team section css start

.our_team_wrapper {
    .garden_team_area {
        background-image: url("../../assets//images/new_bg.png");
        background-position: center center;
        background-size: cover;
        padding: 115px 0 100px;
    }

    *,
    :after,
    :before {
        box-sizing: border-box;
    }

    .container {
        --bs-gutter-x: 1.5rem;
        --bs-gutter-y: 0;
        width: 100%;
        padding-right: calc(var(--bs-gutter-x) * 0.5);
        padding-left: calc(var(--bs-gutter-x) * 0.5);
        margin-right: auto;
        margin-left: auto;
    }

    @media (min-width: 576px) {
        .container {
            max-width: 540px;
        }
    }

    @media (min-width: 768px) {
        .container {
            max-width: 720px;
        }
    }

    @media (min-width: 992px) {
        .container {
            max-width: 960px;
        }
    }

    @media (min-width: 1200px) {
        .container {
            max-width: 1140px;
        }
    }

    @media (min-width: 1400px) {
        .container {
            max-width: 1320px;
        }
    }

    .row {
        --bs-gutter-x: 1.5rem;
        --bs-gutter-y: 0;
        display: flex;
        flex-wrap: wrap;
        margin-top: calc(-1 * var(--bs-gutter-y));
        margin-right: calc(-0.5 * var(--bs-gutter-x));
        margin-left: calc(-0.5 * var(--bs-gutter-x));
    }

    .row > * {
        flex-shrink: 0;
        width: 100%;
        max-width: 100%;
        padding-right: calc(var(--bs-gutter-x) * 0.5);
        padding-left: calc(var(--bs-gutter-x) * 0.5);
        margin-top: var(--bs-gutter-y);
    }

    @media (min-width: 992px) {
        .col-lg-12 {
            flex: 0 0 auto;
            width: 100%;
        }
    }

    @media (min-width: 768px) {
        .col-md-6 {
            flex: 0 0 auto;
            width: 50%;
        }
    }

    @media (min-width: 992px) {
        .col-lg-4 {
            flex: 0 0 auto;
            width: 33.33333333%;
        }
    }

    .witr_section_title {
        margin-bottom: 45px;
    }

    .witr_team_s12 {
        border: none;
        box-shadow: 0 5px 20px 0 #00000014;
        -webkit-transition: 0.5s;
        transition: 0.5s;
        padding-top: 35px;
        border-radius: 10px;
    }

    .cons_singleTeam {
        position: relative;
        text-align: center;
        overflow: hidden;
        -webkit-transition: 0.5s;
        transition: 0.5s;
    }

    .witr_team_s12 {
        background: #fff;
        margin-bottom: 20px;
    }

    .text-center {
        text-align: center !important;
    }

    .text-center {
        text-align: center;
    }

    .witr_team_s12 .front_view {
        border: none;

        img {
            width: 220px;
            height: 280px;
            border-radius: 8px;
        }
    }

    .witr_team_s12 .front_view {
        -webkit-transition: 0.5s;
        transition: 0.5s;
        padding: 0;
    }

    h2 {
        margin-top: 0;
        margin-bottom: 0.5rem;
        font-weight: 500;
        line-height: 1.2;
        color: var(--bs-heading-color);
    }

    h2 {
        font-size: calc(1.325rem + 0.9vw);
    }

    @media (min-width: 1200px) {
        h2 {
            font-size: 2rem;
        }
    }

    h2 {
        font-family: "Lexend Deca", sans-serif;
        font-style: normal;
        line-height: 1.2;
        font-weight: 600;
        -webkit-transition: 0.5s;
        transition: 0.5s;
        font-size: 22px;
        margin-bottom: 5px;
        color: #232323;
    }

    .witr_section_title_inner h2 {
        font-size: 40px;
        margin: auto;
        font-weight: 600;
        line-height: 1.4;
    }

    .witr_section_title_inner h2 {
        font-size: 16px;
        display: inline-block;
        margin-bottom: 10px;
        font-family: Nunito, sans-serif;
        color: #91743e;
        font-weight: 700;
    }

    h3 {
        margin-top: 0;
        margin-bottom: 0.5rem;
        font-weight: 500;
        line-height: 1.2;
        color: var(--bs-heading-color);
    }

    h3 {
        font-size: calc(1.3rem + 0.6vw);
    }

    @media (min-width: 1200px) {
        h3 {
            font-size: 1.75rem;
        }
    }

    h3 {
        font-family: "Lexend Deca", sans-serif;
        font-style: normal;
        line-height: 1.2;
        font-weight: 600;
        -webkit-transition: 0.5s;
        transition: 0.5s;
        font-size: 22px;
        margin-bottom: 5px;
        color: #232323;
    }

    .witr_section_title_inner h3 {
        font-size: 40px;
        margin: auto;
        font-weight: 600;
        line-height: 1.4;
    }

    img {
        vertical-align: middle;
    }

    img {
        border-style: none;
    }

    img {
        max-width: 100%;
        height: auto;
    }

    .all_content_bg_color {
        padding: 35px 25px;
    }

    h5 {
        margin-top: 0;
        margin-bottom: 0.5rem;
        font-weight: 500;
        line-height: 1.2;
        color: var(--bs-heading-color);
    }

    h5 {
        font-size: 1.25rem;
    }

    h5 {
        font-family: "Lexend Deca", sans-serif;
        font-style: normal;
        line-height: 1.2;
        font-weight: 600;
        -webkit-transition: 0.5s;
        transition: 0.5s;
        font-size: 22px;
        margin-bottom: 5px;
        color: #232323;
    }

    .cons_singleTeam h5 {
        padding: 0 0 8px;
    }

    .front_view h5 {
        margin-bottom: 0;
    }

    .cons_singleTeam span {
        color: #91743e;
        font-family: "Nunito", Sans-serif;
        padding: 0 0 14px;
    }

    .front_view span {
        display: inline-block;
        line-height: 28px;
    }

    .witr_team_s12 .con_TMS {
        margin-top: 10px;
    }

    a {
        color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
        text-decoration: underline;
        cursor: pointer;
    }

    a {
        background-color: transparent;
    }

    a {
        font-style: normal;
        font-weight: 400;
    }

    a {
        text-decoration: none;
    }

    a {
        color: #91743e;
        transition: 0.5s;
    }

    .all_team_s_color a {
        color: #91743e;
        background-color: #91743e0f;
        font-size: 16px;
        width: 39px;
        height: 39px;
        line-height: 37px;
        border-style: solid;
        border-color: #91743e0f;
        border-radius: 3px;
        text-align: center;
        margin: 0 3px;
        border: 1px solid #ddd;
        display: inline-block;
    }

    a:hover {
        --bs-link-color-rgb: var(--bs-link-hover-color-rgb);
    }

    a:hover {
        text-decoration: none;
        color: #91743e;
    }

    .all_team_s_color a:hover {
        color: #fff;
        background-color: #91743e;
        border-color: #91743e;
    }

    .all_team_s_color a:hover,
    .witr_team_top ul li a:hover,
    .team_top_icon ul li a:hover {
        color: #fff;
        background-color: #91743e;
        border-color: #91743e;
    }

    i {
        font-style: normal;
        font-weight: 400;
    }

    [class^="icofont-"] {
        font-family: IcoFont !important;
        speak: none;
        font-style: normal;
        font-weight: 400;
        font-variant: normal;
        text-transform: none;
        white-space: nowrap;
        word-wrap: normal;
        direction: ltr;
        line-height: 1;
        -webkit-font-feature-settings: "liga";
        -webkit-font-smoothing: antialiased;
    }

    .icofont-facebook:before {
        content: "\ed37";
    }

    .icofont-x:before {
        content: "\f03d";
    }

    .icofont-tumblr:before {
        content: "\ed78";
    }

    .icofont-vimeo:before {
        content: "\ed7f";
    }
}
