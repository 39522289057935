* {
    // transition: all 1s ease-in-out;
}

button:disabled {
    opacity: 0.3;
    pointer-events: none;
}

.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flex-start {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.flex-gap-10 {
    gap: 10px;
}
.flex-gap-20 {
    gap: 20px;
}
.flex-gap-30 {
    gap: 30px;
}
.c-pointer {
    cursor: pointer;
}

.vertical_line {
    border-left: 1px solid #ccc;
    height: 100%;
    width: 1px;
}

.horizontal_line {
    border-top: 1px solid #ccc;
    width: 100%;
    height: 1px;
}
.bold {
    font-weight: 600;
}
.green {
    color: #13c519;
}

// ico font icons

.icofont-facebook:before {
    content: "\ed37";
}

.icofont-x:before {
    content: "\f03d";
}

.icofont-tumblr:before {
    content: "\ed78";
}

.icofont-vimeo:before {
    content: "\ed7f";
}

.icofont-instagram:before {
    content: "\ed46";
}

.icofont-twitter:before {
    content: "\edf7";
}

.icofont-linkedin:before {
    content: "\ed4d";
}

.icofont-youtube:before {
    content: "\edf9";
}

.icofont-pinterest:before {
    content: "\ed5d";
}

.icofont-youtube:before {
    content: "\ecbb";
}

.back_gradient_anim_text {
    background: linear-gradient(-45deg, #66fe38, #0c71c3, hsl(37, 96%, 48%), #91743e);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: back_gradient_anim 8s ease-in-out infinite;
    -webkit-animation: back_gradient_anim 8s ease-in-out infinite;
    background-size: 300%;
    color: transparent;
}

@keyframes back_gradient_anim {
    0% {
        background-position: 0 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    to {
        background-position: 0 50%;
    }
}

.section_title_small {
    font-size: 16px;
    display: inline-block;
    margin-bottom: 10px;
    font-family: Nunito, sans-serif;
    color: #91743e;
    font-weight: 700;
    text-transform: uppercase;

    @media (max-width: 768px) {
        font-size: 14px;
        margin-bottom: 8px;
    }
}

.section_title_big {
    font-size: 45px !important;
    margin: auto;
    font-weight: 700 !important;
    line-height: 1.4;

    @media (max-width: 768px) {
        font-size: 30px !important;
    }
}
.product_review_modal {
    .review_modal_box {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: #ffffff;
        border-radius: 8px;
        width: 50%;
        outline: none;

        .review_modal_header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 15px 24px;
            // border-bottom: 1px solid #ccc;
            box-shadow:
                0px 0px 3px 0px rgba(20, 26, 31, 0.1),
                0px 4px 20px 0px rgba(20, 26, 31, 0.15);

            .review_modal_title {
                font-size: 20px;
                font-weight: 600;
            }
            .close_icon {
                cursor: pointer;
            }
        }
        .review_modal_body {
            padding: 15px 24px 24px;
            .product_review_form {
                display: flex;
                flex-direction: column;
                gap: 20px;
                .review_form_field {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    .review_form_label {
                        font-size: 16px;
                        font-weight: 600;
                    }
                    .product_review_rating {
                        width: fit-content;
                    }
                }
            }
        }
    }
}

.rating_stars_block {
    svg {
        fill: #d2a34c;
    }
}

.foft_button_primary {
    padding: 8px 12px;
    border: 1px solid #91743e;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
    background-color: #91743e;
    color: #ffffff;
    outline: none;
    width: 100%;
    &:focus {
        outline: none;
    }
}

.contact_us_screen {
    padding: 20px 120px;
    // height: 100vh;

    .contact_us_title {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 20px;
    }
}

@media (max-width: 768px) {
    .contact_us_screen {
        padding: 10px;
        .contact_us_title {
            font-size: 20px;
            margin-bottom: 10px;
        }
    }
}
