.login_screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    padding: 50px 0px 50px 0px;

    .site_title {
        font-size: 40px;
        font-family: "rockwell";
        color: #000;
        margin-bottom: 20px;
    }

    .login_container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: auto;
        height: auto;
        background-color: #fff;
        border-radius: 8px;
        box-shadow: 0px 0px 10px 0px #a5e7b4;
        padding: 20px 100px;

        .login_title {
            font-size: 30px;
            font-family: "rockwell";
            color: #000;
            margin-bottom: 20px;
        }
        .login_form {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .login_form_field {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: left;
                margin-top: 10px;

                label {
                    font-size: 20px;
                    font-family: "rockwell";
                    color: #000;
                    margin-bottom: 5px;
                    text-align: left;
                }
                input {
                    padding: 10px 20px 5px 20px;
                    border-radius: 8px;
                    cursor: pointer;
                    font-size: 18px;
                    font-family: "rockwell";
                    color: #000;
                    outline: none;
                    text-align: left;
                    width: 300px;
                }
            }
            button {
                margin-top: 20px;
                padding: 10px 20px 5px 20px;
                background-color: #a5e7b4;
                border-radius: 8px;
                margin-bottom: 20px;
                cursor: pointer;
                font-size: 18px;
                font-family: "rockwell";
                color: #000;
                border: none;
                outline: none;
                width: 100%;
            }
        }
    }

    .error_message {
        font-size: 16px;
        font-family: "rockwell";
        color: red;
        margin-top: 5px;
        width: 300px;
    }
}

@media (max-width: 768px) {
    .login_screen {
        padding: 20px 10px;
        margin: 10px;

        .site_title {
            font-size: 30px;
            font-family: "rockwell";
            color: #000;
            margin-bottom: 20px;
        }

        .login_container {
            padding: 20px 50px;

            .login_title {
                font-size: 20px;
                font-family: "rockwell";
                color: #000;
                margin-bottom: 20px;
            }
            .login_form {
                .login_form_field {
                    label {
                        font-size: 16px;
                        font-family: "rockwell";
                        color: #000;
                        margin-bottom: 5px;
                        text-align: left;
                    }
                    input {
                        padding: 10px 20px 5px 20px;
                        border-radius: 8px;
                        cursor: pointer;
                        font-size: 14px;
                        font-family: "rockwell";
                        color: #000;
                        outline: none;
                        text-align: left;
                        width: 100%;
                    }
                }
                button {
                    margin-top: 20px;
                    padding: 10px 20px 5px 20px;
                    background-color: #a5e7b4;
                    border-radius: 8px;
                    margin-bottom: 20px;
                    cursor: pointer;
                    font-size: 14px;
                    font-family: "rockwell";
                    color: #000;
                    border: none;
                    outline: none;
                    width: 100%;
                }
            }
        }
    }
}
