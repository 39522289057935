.order_review_page {
    padding: 20px 50px;

    .order_review_wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 20px;
        margin-top: 20px;

        .order_left_sec {
            width: 70%;

            .address_wrapper {
                padding: 20px;
                border: 1px solid #ccc;
                border-radius: 20px;

                .address_title {
                    font-size: 20px;
                    font-weight: 600;
                    margin-bottom: 10px;
                }
                .address_details {
                    font-size: 16px;
                    font-weight: 400;
                    margin-top: 10px;
                    position: relative;

                    .address_name {
                        font-weight: 600;
                    }
                    .address_text {
                        width: 60%;
                    }

                    .address_pincode {
                        span {
                            font-weight: 600;
                        }
                    }
                    .address_mobile {
                        span {
                            font-weight: 600;
                        }
                    }

                    .address_edit_wrapper {
                        position: absolute;
                        top: 0;
                        right: 0;
                        cursor: pointer;
                        color: #13c519;
                        border: 1px solid #ccc;
                        border-radius: 50%;
                        padding: 10px;

                        &:hover {
                            background-color: #13c519;
                            color: #ffffff;
                        }
                    }
                }
            }
            .order_products_list_wrapper {
                padding: 20px;
                border: 1px solid #ccc;
                border-radius: 20px;
                margin-top: 20px;

                .order_products_title {
                    font-size: 20px;
                    font-weight: 600;
                    margin-bottom: 10px;
                }

                .product_details_wrapper {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    padding-top: 20px;

                    .product_image img {
                        width: 100px;
                        height: 100px;
                        border-radius: 10px;
                        background-color: #ccc;
                        margin-right: 20px;
                    }

                    .product_details {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;

                        .product_name {
                            font-size: 18px;
                            font-weight: 600;
                        }
                        .product_price {
                            font-size: 16px;
                            font-weight: 500;
                            display: flex;
                            align-items: center;
                            gap: 10px;

                            .actual_price {
                                text-decoration: line-through;
                                margin-right: 10px;
                            }
                            .discount_price {
                                color: #13c519;
                                font-size: 22px;
                            }
                        }
                        .you_sava_box {
                            padding: 2px 10px;
                            background-color: #13c519;
                            color: #ffffff;
                            font-size: 14px;
                            width: fit-content;
                            border-radius: 5px;
                        }
                        .sold_by {
                            font-size: 14px;
                            font-weight: 500;
                            margin-top: 5px;
                        }
                    }
                }
            }
        }
        .order_right_sec {
            width: 30%;
            // background-color: green;

            .order_summary_wrapper {
                margin-top: 20px;
                border: 1px solid #ccc;
                border-radius: 20px;

                .free_delivery_banner {
                    background-color: #13c519;
                    color: #ffffff;
                    padding: 10px;
                    border-radius: 20px 20px 0 0;
                    font-size: 14px;
                    font-weight: 500;
                    display: flex;
                    justify-content: center;

                    .celebration_icon {
                        font-size: 20px;
                        margin-right: 10px;
                    }
                    .banner_text {
                        font-size: 14px;
                        font-weight: 500;
                    }
                }

                .payment_details {
                    padding: 10px 20px 20px 20px;

                    .payment_title {
                        font-size: 18px;
                        font-weight: 600;
                        margin-bottom: 10px;
                    }

                    .horizontal_line {
                        margin: 10px 0;
                    }

                    .payment_details_row {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;

                        .payment_details_row_title {
                            font-size: 16px;
                        }
                        .payment_details_row_value {
                            font-size: 16px;
                            font-weight: 500;
                        }
                    }
                }
            }

            .make_payment_btn {
                width: 100%;
                margin-top: 20px;
                padding: 10px;
                border-radius: 10px;
                background-color: #f9a109;
                color: #ffffff;
                font-size: 16px;
                font-weight: 600;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 10px;
                cursor: pointer;

                &:hover {
                    background-color: #13c519;
                    color: #ffffff;
                }
            }
        }
    }

    .order_steps_wrapper {
        display: flex;
        flex-direction: row;
        // gap: 20px;
        justify-content: space-between;
        align-items: center;
        background-color: #e5f7ed;
        padding: 15px 25px;
        border-radius: 20px;

        .order_step {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 5px;
            width: 100px;

            .order_step_number {
                width: 30px;
                height: 30px;
                border-radius: 50%;
                background-color: #e5f7ed;
                color: #ffffff;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 16px;
                font-weight: 600;
                border: 1px solid #13c519;
            }
            .order_step_title {
                font-size: 14px;
                font-weight: 500;
                text-align: center;
            }

            &.complete {
                .order_step_number {
                    .tick_icon {
                        color: #13c519;
                        font-size: 20px;
                    }
                }
                .order_step_title {
                    color: #13c519;
                }
            }

            &.active {
                .order_step_number {
                    background-color: #13c519;
                    color: #fff;
                }
                .order_step_title {
                    color: #000;
                    font-weight: 600;
                }
            }

            &.disabled {
                opacity: 0.4;

                .order_step_number {
                    color: #000;
                }
                .order_step_title {
                    color: #000;
                }
            }
        }

        .horizontal_line_wrapper {
            position: relative;

            .horizontal_line {
                border: 1px solid #ccc;
                width: 70px;
                height: 1px;
                position: absolute;
                left: -35px;
                top: -15px;

                &.complete {
                    border-color: #13c519;
                }

                &.disabled {
                    border-color: #ccc;
                    opacity: 0.4;
                }
            }
        }
    }
}

@media (max-width: 1200px) and (min-width: 900px) {
    .order_review_page {
        .order_steps_wrapper {
            padding: 10px 0 !important;
        }
        .order_review_wrapper .order_right_sec .order_steps_wrapper .horizontal_line_wrapper .horizontal_line {
            border: 1px solid #ccc;
            width: 60px;
            height: 1px;
            position: absolute;
            left: -30px;
            top: -15px;
        }
    }
}

@media (max-width: 768px) {
    .order_review_page {
        padding: 10px 10px;

        .order_review_wrapper {
            margin-top: 10px;
            flex-direction: column;

            .order_left_sec {
                width: 100%;

                .address_wrapper {
                    padding: 10px;
                    border: 1px solid #ccc;
                    border-radius: 20px;

                    .address_title {
                        font-size: 16px;
                        font-weight: 600;
                        margin-bottom: 10px;
                    }
                    .address_details {
                        font-size: 15px;
                        font-weight: 400;
                        margin-top: 10px;
                        position: relative;

                        .address_name {
                            font-weight: 600;
                        }
                        .address_text {
                            width: 80%;
                        }

                        .address_pincode {
                            span {
                                font-weight: 600;
                            }
                        }
                        .address_mobile {
                            span {
                                font-weight: 600;
                            }
                        }

                        .address_edit_wrapper {
                            position: absolute;
                            top: 0;
                            right: 0;
                            cursor: pointer;
                            color: #13c519;
                            border: 1px solid #ccc;
                            border-radius: 50%;
                            padding: 10px;

                            &:focus {
                                background-color: #13c519;
                                color: #ffffff;
                            }
                        }
                    }
                }

                .order_products_list_wrapper {
                    padding: 10px;
                    border: 1px solid #ccc;
                    border-radius: 20px;
                    margin-top: 10px;

                    .order_products_title {
                        font-size: 16px;
                        font-weight: 600;
                        margin-bottom: 10px;
                    }

                    .product_details_wrapper {
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        padding-top: 10px;
                        position: relative;

                        .product_image img {
                            width: 80px;
                            height: 80px;
                            border-radius: 10px;
                            background-color: #ccc;
                            margin-right: 10px;
                        }

                        .product_details {
                            width: 100%;
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-start;

                            .product_name {
                                font-size: 16px;
                                font-weight: 600;
                            }
                            .product_price {
                                font-size: 14px;
                                font-weight: 500;
                                display: flex;
                                align-items: center;
                                gap: 10px;

                                .actual_price {
                                    text-decoration: line-through;
                                    margin-right: 10px;
                                }
                                .discount_price {
                                    color: #13c519;
                                    font-size: 18px;
                                }
                            }
                            .you_sava_box {
                                padding: 2px 10px;
                                background-color: #13c519;
                                color: #ffffff;
                                font-size: 12px;
                                width: fit-content;
                                border-radius: 5px;
                            }
                            .sold_by {
                                font-size: 12px;
                                font-weight: 500;
                                margin-top: 5px;
                            }
                        }

                        .plus_minus_wrapper {
                            position: absolute;
                            right: 0;
                            top: 50px;
                            gap: 5px;
                        }
                    }
                }
            }

            .order_right_sec {
                width: 100%;
            }
        }

        .order_steps_wrapper {
            margin-top: 10px;
            margin-bottom: 15px;
        }
    }
}
