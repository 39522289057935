// .header_wrapper {
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     padding: 15px 40px;

//     .user_name {
//         font-weight: 600;
//         text-transform: capitalize;
//     }

//     .header_icons {
//         color: #91743e;
//         font-size: 40px;
//         cursor: pointer;
//     }

//     .menu_items {
//         font-size: 16px;
//         color: #91743e;
//         width: 200px;
//         height: 50px;
//         display: flex;
//         justify-content: center;
//         align-items: center;
//         cursor: pointer;

//         &:hover {
//             color: #fff;
//             transition: all 0.1s ease-in-out !important;
//             background-color: #91743e;
//         }
//     }
// }

@media (prefers-reduced-motion: no-preference) {
    :root {
        scroll-behavior: smooth;
    }
}

.foft-header-top {
    background: #91743e;
    padding: 10px 0;
}

.foft-header-top {
    padding-right: 200px;
    padding-left: 200px;
}

@media (min-width: 1200px) and (max-width: 1440px) {
    .foft-header-top {
        padding-right: 30px;
        padding-left: 30px;
    }
}

@media (min-width: 1365px) and (max-width: 1660px) {
    .foft-header-top {
        padding-right: 50px !important;
        padding-left: 50px !important;
    }
}

*,
:after,
:before {
    box-sizing: border-box;
}

.container-fluid {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    width: 100%;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    margin-right: auto;
    margin-left: auto;
}

.row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-0.5 * var(--bs-gutter-x));
    margin-left: calc(-0.5 * var(--bs-gutter-x));
}

.row > * {
    flex-shrink: 0;
    // width: 100%;
    // max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    margin-top: var(--bs-gutter-y);
}

// @media (min-width: 576px) {
//     .col-sm-12 {
//         flex: 0 0 auto;
//         width: 100%;
//     }
// }

// @media (min-width: 768px) {
//     .col-md-9 {
//         flex: 0 0 auto;
//         width: 75%;
//     }
// }

// @media (min-width: 992px) {
//     .col-lg-9 {
//         flex: 0 0 auto;
//         width: 75%;
//     }
// }

// @media (min-width: 1200px) {
//     .col-xl-9 {
//         flex: 0 0 auto;
//         width: 75%;
//     }
// }

// @media (min-width: 768px) {
//     .col-md-3 {
//         flex: 0 0 auto;
//         width: 25%;
//     }
// }

// @media (min-width: 992px) {
//     .col-lg-3 {
//         flex: 0 0 auto;
//         width: 25%;
//     }
// }

// @media (min-width: 1200px) {
//     .col-xl-3 {
//         flex: 0 0 auto;
//         width: 25%;
//     }
// }

.text-left {
    text-align: left;
}

.top-address {
    display: inline-block;
}

p {
    margin-top: 0;
    margin-bottom: 1rem;
}

p {
    font-style: normal;
}

.top-address p {
    margin-bottom: 0;
}

ul {
    padding-left: 2rem;
}

ul {
    margin-top: 0;
    margin-bottom: 1rem;
}

ul {
    margin: 0;
    padding: 0;
}

ul {
    font-style: normal;
    font-weight: 400;
}

.text-right {
    text-align: right;
}

.top-right-menu ul.social-icons {
    margin: 0;
    padding: 0;
}

a {
    color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
    text-decoration: underline;
    cursor: pointer;
}

a {
    background-color: transparent;
}

a {
    font-style: normal;
    font-weight: 400;
}

a {
    text-decoration: none;
}

a {
    color: #91743e;
    transition: 0.5s;
}

.top-address p a {
    margin-right: 20px;
}

.top-address p a {
    display: inline-block;
    text-decoration: none;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

.top-address p a {
    color: #fff;
    font-size: 15px;
    font-weight: 500;
}

a:hover {
    --bs-link-color-rgb: var(--bs-link-hover-color-rgb);
}

a:hover {
    text-decoration: none;
    color: #91743e;
}

li {
    list-style: none;
}

li {
    font-style: normal;
    font-weight: 400;
}

.top-right-menu ul.social-icons li {
    display: inline-block;
    margin-left: 15px;
    position: relative;
}

i {
    font-style: normal;
    font-weight: 400;
}

[class^="icofont-"] {
    font-family: IcoFont !important;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    line-height: 1;
    -webkit-font-feature-settings: "liga";
    -webkit-font-smoothing: antialiased;
}

.top-address p a i {
    font-size: 13px;
    color: #fff;
    margin-right: 5px;
}

.icofont-home:before {
    content: "\ef47";
}

.icofont-ui-call:before {
    content: "\ec46";
}

.icofont-envelope:before {
    content: "\ef14";
}

.icofont-star:before {
    content: "\f000";
}

.top-right-menu ul.social-icons li a {
    display: inline-block;
    text-decoration: none;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    font-size: 15px;
}

.top-right-menu ul.social-icons li a {
    color: #fff;
    font-size: 15px;
    font-weight: 500;
}

.top-right-menu .social-icons li a:hover {
    color: #17161a;
}

.top-right-menu .social-icons li a:hover,
.top-right-menu .social-icons li a i:hover {
    color: #17161a;
}

.icofont-facebook:before {
    content: "\ed37";
}

.icofont-x:before {
    content: "\f03d";
}

.icofont-instagram:before {
    content: "\ed46";
}

.icofont-pinterest:before {
    content: "\ed5d";
}

// top header

/* ===== 2. Menu Area CSS ===== */
.foft_nav_area,
.trp_nav_area .container-fluid,
.transprent-menu .foft_nav_area .container-fluid {
    padding-right: 200px;
    padding-left: 200px;
}
.trp_nav_area {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 9999;
    transition: 0.5s;
}
.logo-right,
.logo-left {
    align-items: center;
}
.mobile_menu_logo.text-center {
    padding: 20px 0;
}

/* LOGO CSS */
.logo a {
    display: inline-block;
}
.foft-main-menu .logo a.main_sticky_l {
    display: none;
}
/* logo sticky */
.foft-main-menu .logo a.main_sticky_main_l {
    display: block;
}
.foft-main-menu .logo a.main_sticky_l {
    display: none;
}
/* logo pre */
.foft-main-menu .prefix .logo a.main_sticky_main_l {
    display: none;
}
.foft-main-menu .prefix .logo a.main_sticky_l {
    display: block;
}

/* MENU text CSS */
.foft_menu ul {
    text-align: right;
    list-style: none;
}
.foft_menu > ul > li {
    display: inline-block;
    position: relative;
}
.foft_menu > ul > li > a {
    display: block;
    margin: 44px 20px;
    transition: 0.5s;
    position: relative;
    font-size: 16px;
    font-weight: 700;
    color: #272727;
    font-family: "lato";
}
.foft_menu > ul > li > a:before,
.foft_menu > ul > li.current > a:before {
    background: #ffb600 none repeat scroll 0% 0;
    bottom: -7px;
    content: "";
    height: 2px;
    left: 0;
    margin: auto;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    position: absolute;
    right: 0;
    -webkit-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
    width: 15%;
    display: none;
}
.foft_menu ul li:last-child a {
    margin-right: 15px !important;
}
.foft_menu > ul > li.current > a,
.foft_menu > ul > li:hover > a,
.creative_header_menu > ul > li:hover > a {
    color: #91743e;
    background-color: transparent;
}
.foft_menu > ul > li:hover > a:before,
.foft_menu > ul > li.current > a:before {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

.transprent-menu .foft_nav_area {
    left: 0;
    right: 0;
    top: 0;
    z-index: 9999;
    background-color: transparent;
    padding: 0;
    transition: 0.5s;
    position: absolute;
}
.foft_nav_area,
.transprent-menu .foft_nav_area,
.hmenu_box_style,
.mini_shop_content a i {
    background-color: #f6f4ec;
}
.foft_nav_area.postfix {
    transition: 0.3s;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
}
.foft_nav_area.prefix,
.hbg2 {
    background-color: #91743e;
}
.foft_nav_area.prefix .foft_menu > ul > li > a {
    color: #fff;
}
.foft_nav_area.prefix .foft_menu > ul > li.current > a {
    color: #91743e;
}
.search_popup_button,
.tx_mmenu_together {
    align-items: center;
    display: flex;
    justify-content: flex-end;
}
.tx_mmenu_together .donate-btn-header {
    padding: 0;
}
.prefix .main_menu_header_address_text h3,
.prefix .main_menu_header_address_text h4 a,
.prefix .main_menu_header_icon i {
    color: #fff;
}
/* foft 8 menu color */
.heading_style_4 .foft_menu > ul > li > a {
    color: #fff;
}
.heading_style_4 .main-search-menu .em-header-quearys .em-quearys-menu i {
    color: #fff;
}

/* sub menu style */
.foft_menu ul .sub-menu {
    position: absolute;
    left: 0;
    top: 130%;
    width: 240px;
    text-align: left;
    background: #fff;
    margin: 0;
    padding: 15px 8px 8px 25px;
    z-index: 9999;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.09);
    transition: 0.2s;
    opacity: 0;
    visibility: hidden;
}
.foft_menu ul li:hover > .sub-menu {
    transition: 0.5s;
    opacity: 1;
    visibility: visible;
    top: 100%;
    z-index: 9;
}
.foft_menu ul .sub-menu li {
    position: relative;
    transition: 0.5s;
}
.foft_menu ul .sub-menu li a {
    display: block;
    margin: 0;
    margin-bottom: 6px;
    letter-spacing: normal;
    font-size: 16px;
    font-weight: 700;
    transition: 0.5s;
    color: #272727;
    visibility: inherit !important;
    padding: 4px 0px;
    font-family: "lato";
}
.foft_menu ul .sub-menu li:hover > a,
.foft_menu ul .sub-menu .sub-menu li:hover > a,
.foft_menu ul .sub-menu .sub-menu .sub-menu li:hover > a,
.foft_menu ul .sub-menu .sub-menu .sub-menu .sub-menu li:hover > a {
    color: #14b1bb;
}
.foft_menu ul .sub-menu li:hover {
    margin-left: 16px;
}
.foft_menu ul .sub-menu li:before {
    left: -18px;
}
.foft_menu ul .sub-menu li:hover:before {
    opacity: 1;
}

.foft_menu ul .sub-menu li:before {
    opacity: 0;
    -webkit-transition: 0.1s;
    transition: 0.1s;
    position: absolute;
    top: 50%;
    content: "//";
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}
.foft_menu ul .sub-menu .sub-menu .sub-menu .sub-menu li:hover > a,
.foft_menu ul .sub-menu .sub-menu .sub-menu li:hover > a,
.foft_menu ul .sub-menu .sub-menu li:hover > a,
.foft_menu ul .sub-menu li:hover:before,
.foft_menu ul .sub-menu li:hover > a {
    color: #91743e;
}
/* sub menu 2 */
.foft_menu ul .sub-menu .sub-menu {
    left: 100%;
    top: 130%;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    visibility: hidden;
}
.foft_menu ul .sub-menu li:hover > .sub-menu {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    visibility: visible;
    top: 0%;
}
/* sub menu 3 */
.foft_menu ul .sub-menu .sub-menu li {
    position: relative;
}
.foft_menu ul .sub-menu .sub-menu .sub-menu {
    right: 100%;
    left: auto;
    top: 130%;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    visibility: hidden;
}
.foft_menu ul .sub-menu .sub-menu li:hover > .sub-menu {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    visibility: visible;
    top: 0%;
}
/* sub menu 4 */
.foft_menu ul .sub-menu .sub-menu .sub-menu li {
    position: relative;
}
.foft_menu ul .sub-menu .sub-menu .sub-menu li:hover > .sub-menu {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    visibility: visible;
    top: 0%;
}

/* has menu icon */
.foft-main-menu .menu-item-has-children > a:after {
    margin-left: 8px;
    content: "\f107";
    font-family: FontAwesome !important;
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    font-size: 13px;
    opacity: 0.5;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    font-weight: 600;
}
.foft-main-menu .menu-item-has-children .menu-item-has-children > a:after {
    margin-left: 8px;
    content: "\f107";
}

/* quearys area css */
.foft_menu.main-search-menu > .em-header-quearys,
.em-quearys-top.msin-menu-search {
    display: inline-block;
}
.em-top-quearys-area {
    position: relative;
}
.foft_menu div.em-header-quearys {
    text-align: center;
}

.em-quearys-inner {
    visibility: hidden;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: -150%;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0);
    z-index: 10000;
    -webkit-transition:
        all 0s 0s cubic-bezier(0.32, 0, 0, 1),
        background 0.3s 0s cubic-bezier(0.32, 0, 0, 1),
        -webkit-transform 0.3s 0.15s cubic-bezier(0.32, 0, 0, 1);
    transition:
        all 0s 0s cubic-bezier(0.32, 0, 0, 1),
        background 0.3s 0s cubic-bezier(0.32, 0, 0, 1),
        -webkit-transform 0.3s 0.15s cubic-bezier(0.32, 0, 0, 1);
    transition:
        all 0s 0s cubic-bezier(0.32, 0, 0, 1),
        transform 0.3s 0.15s cubic-bezier(0.32, 0, 0, 1),
        background 0.3s 0s cubic-bezier(0.32, 0, 0, 1);
    transition:
        all 0s 0s cubic-bezier(0.32, 0, 0, 1),
        transform 0.3s 0.15s cubic-bezier(0.32, 0, 0, 1),
        background 0.3s 0s cubic-bezier(0.32, 0, 0, 1),
        -webkit-transform 0.3s 0.15s cubic-bezier(0.32, 0, 0, 1);
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    visibility: hidden;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    left: -150%;
    background: rgba(0, 0, 0, 0);
}
.em-s-open {
    visibility: visible;
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    left: 0;
    background: #000000e6 !important;
    -webkit-transform: scale(1);
    transform: scale(1);
}
.em-quearys-form {
    width: 100%;
    max-width: 895px;
    margin: auto;
    padding: 30px 0;
}
.top-form-control {
    position: relative;
}
.top-form-control input {
    background: transparent;
    color: #fff;
    font-size: 50px;
    font-weight: 500;
    height: 100px;
    padding: 0 40px 0 15px;
    width: 100%;
    border: none;
    -webkit-transition: 1s;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    border-bottom: 3px solid #fff;
}
.top-form-control input::placeholder {
    color: #fff;
}
textarea,
input {
    outline: none;
}
.top-form-control button.top-quearys-style {
    position: absolute;
    right: 6px;
    top: 50%;
    background: transparent;
    font-size: 45px;
    border: none;
    color: #fff;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}
.main-search-menu .em-header-quearys .em-quearys-menu i {
    background: 0;
    height: auto !important;
    width: auto !important;
    line-height: inherit !important;
    font-size: 17px;
    margin-left: 15px;
    color: #91743e;
    cursor: pointer;
}
.foft_nav_area.prefix .main-search-menu .em-header-quearys .em-quearys-menu i {
    color: #fff;
}

.mrt10 {
    margin-top: 10px;
}
.em-quearys-menu i {
    background: #91743e;
    border-radius: 50px;
    display: inline-block;
    text-align: center;
    height: 36px;
    width: 36px;
    line-height: 36px;
    font-size: 14px;
    color: #fff;
}

.main_menu_address_a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    position: relative;
}
.main_menu_address_a::before {
    background: #fbfbfb40;
}
.main_menu_address_a:before {
    content: "";
    position: absolute;
    width: 1px;
    height: 107px;
    left: 0;
}
.main_menu_header_icon {
    margin-left: 25px;
    margin-right: 10px;
}
.main_menu_header_icon i {
    color: #14b1bb;
    font-size: 30px;
}
.main_menu_header_address_text h3,
.main_menu_header_address_text h4 a {
    margin: 0;
    font-weight: 600;
    font-size: 18px;
}
.main_menu_header_address_text h3 {
    color: #14b1bb;
}
.main_menu_header_address_text h4 {
    margin-bottom: 0;
    color: #030925;
}

/* mobile-menu  */
.mobile-menu {
    display: none !important;
}
.mobile_logo_area {
    display: none !important;
}
.mobile_menu_logo.text-center {
    padding: 20px 0;
}
.mean-container .mean-bar {
    padding: 0;
}
.main_menu_div {
    position: relative;
}
.mean-container .mean-bar {
    float: none;
    background: #91743e;
    overflow: hidden;
}
/* menu button */
a.dtbtn {
    border: none;
    display: block;
    font-size: 16px;
    margin-left: 20px;
    font-weight: 700;
    border-radius: 30px;
    padding: 13px 25px;
    background: #91743e;
    color: #fff;
}
a.dtbtn:hover {
    background: #323a43;
}

/* End Menu Area CSS */

@media (min-width: 1200px) and (max-width: 1440px) {
    .foft_menu > ul > li > a {
        margin-right: 15px;
    }

    .main-search-menu .em-header-quearys .em-quearys-menu i {
        margin-left: 10px;
    }

    .skill_inner {
        padding: 100px 30px 70px 70px;
    }
    .foft-header-top {
        padding-right: 30px;
        padding-left: 30px;
    }
    .foft_nav_area,
    .trp_nav_area .container-fluid,
    .transprent-menu .foft_nav_area .container-fluid {
        padding-right: 30px;
        padding-left: 30px;
    }
    .garden_project_area {
        padding: 95px 20px 172px;
    }
    .garden_portfolio_area .witr_section_title_inner p {
        width: 77%;
    }

    .garden_portfolio_area .witr_section_title_inner p {
        width: 74% !important;
    }
}
@media (min-width: 1024px) and (max-width: 1600px) {
    .em_slider_social {
        top: 45%;
    }
}
@media (min-width: 1170px) and (max-width: 1366px) {
    .em_gardeneative_header:after {
        -webkit-transform: rotate(-114deg);
        transform: rotate(-114deg);
    }
    .gardeneative_header_address {
        padding-left: 0;
    }
}
@media (min-width: 1365px) and (max-width: 1660px) {
    .foft-header-top,
    .foft_nav_area,
    .transprent-menu .foft_nav_area,
    .trp_nav_area {
        padding-right: 50px !important;
        padding-left: 50px !important;
    }
}
@media (min-width: 992px) and (max-width: 1199px) {
    .witr_swiper_height {
        height: 700px;
    }
    .main-search-menu .em-header-quearys .em-quearys-menu i {
        margin-left: 10px;
    }

    .foft-header-top,
    .foft_nav_area,
    .transprent-menu .foft_nav_area,
    .trp_nav_area {
        padding-right: 15px !important;
        padding-left: 15px !important;
    }
    .witr_sw_text_area {
        width: 90% !important;
    }
    .witr_swiper_content h2,
    .witr_swiper_content h3 {
        font-size: 55px;
    }
    .hmenu_box_style .foft_menu > ul > li > a {
        margin-right: 15px;
    }
    .em_gardeneative_header:before {
        width: 19%;
    }
    .em_gardeneative_header:after {
        display: none;
    }
    .garden_top_addess.top-address p {
        text-align: center !important;
    }
    .theme_garden_logo {
        text-align: center;
        margin-bottom: 30px;
    }
    .d_lg_block {
        display: block;
    }
    .gardeneative_header_address {
        padding-left: 0;
    }
    .em_gardeneative_header:before {
        display: none;
    }
    .menu-height-space {
        height: 0;
    }
    .em_gardeneative_header:after {
        -webkit-transform: rotate(-100deg);
        transform: rotate(-100deg);
    }
    .foft_menu > ul > li > a {
        font-size: 15px;
        margin-left: 4px;
        margin-right: 4px;
    }
    a.dtbtn {
        font-size: 15px;
    }
    .foft_menu > ul > li > a {
        margin-left: 15px;
        margin-right: 15px;
    }
    .witr_containers {
        width: 95%;
    }
    .text-left .witr_ds_content_inner h1,
    .text-left .witr_ds_content_inner h2,
    .text-left .witr_ds_content_inner h3,
    .witr_ds_content_inner p {
        width: 51%;
    }
    .witr_section_title_inner h1,
    .witr_section_title_inner h2,
    .witr_section_title_inner h3 {
        font-size: 32px;
    }
    .witr_section_title_inner h2 {
        font-size: 16px;
    }
    /* service area css */
    .garden_service_area .em-service2 .em-service-icon i {
        margin-bottom: 15px;
    }
    /* feature area css */
    .garden_feature_area .sub-item h3 a {
        font-size: 22px;
    }
    /* about area css */
    .garden_about_content_inner {
        padding: 0px 0px 0 10px;
    }
    /* about area css */
    .garden_about_image_inner .single_image img {
        margin-left: 0;
    }
    .garden_about_content_inner {
        padding: 0px 0px 0 0;
    }
    .garden_about_content_inner .headh1_style {
        padding-top: 0;
        padding-bottom: 0;
    }
    .about_list_image_inner {
        margin-left: 0;
    }
    .about_list_image_inner .single_image img {
        margin-right: 0;
        margin-top: 20px;
        margin-left: 10px;
    }
    .about_content_inner {
        padding: 0;
    }
    .witr_s_flex {
        display: inherit;
    }
    .em-service-icon img {
        margin-bottom: 20px;
    }
    .service_inner {
        margin: 0 -15px;
    }
    .about_content_inner .witr_section_title_inner h3 {
        font-size: 34px;
        padding-top: 8px;
    }
    .about_content_inner .witr_s_flex {
        display: flex;
    }
    .garden_about_area .all_icon_color img {
        width: 80px;
    }
    /* testimonial area css */
    .em_single_testimonial {
        padding: 50px 25px 50px 25px;
    }
    .em_crating i.active {
        font-size: 22px;
    }

    .witr_testi_itemt {
        padding: 0;
    }
    /* call action area css */
    .foft_content h2,
    .foft_content h3 {
        font-size: 25px;
    }
    .cal_to_toggole {
        padding: 56px 30px 0px 0px;
    }
    /* choose area css */
    .garden_choose_area .garden_about_content_inner {
        padding: 0px 0px 0 0;
    }
    .garden_choose_list_inner {
        display: inherit;
    }
    .garden_choose_area .witr_widget_list {
        margin-bottom: 30px;
    }
    .garden_choose_area .witr_btn {
        margin-top: 10px;
    }
    /* tab area css */
    .witr_adv_tab_menu {
        margin: 0;
    }
    .tab-content {
        margin: 0;
    }
    /* skill area css */
    .skill_inner {
        margin: 0px 0px 0px -10px;
        padding: 30px 10px 70px 20px;
    }
    /* contact area css */
    .apartment_area {
        margin-left: 0;
        margin-right: 0;
    }
    .garden_contact_area .headh1_style {
        font-size: 18px;
    }
    .garden_contact_area .headh1_style::before {
        top: 30%;
    }
    .witr_h_h9 .foft_menu > ul > li > a {
        margin-left: 2px;
        margin-right: 2px;
    }
    .witr_h_h20 .foft_menu > ul > li > a {
        margin-left: 6px;
        margin-right: 6px;
    }
    .accordion-content {
        padding: 23px 25px;
    }
    .shortcode_content h2 {
        font-size: 30px !important;
    }
    .witr_wid_col .elementor-column.elementor-col-20,
    .witr_wid_col .elementor-column[data-col="20"] {
        width: 33.33% !important;
    }
    .widget .widget_media_image img {
        width: 100%;
    }
    /* blog area css */
    .recent-post-text h4 a {
        font-size: 16px;
    }
    .blog-left-side .blog-title > ul > li > a {
        margin-right: 8px;
    }
    .recent-post-text > h4 {
        margin-top: 15px;
        line-height: 1;
    }
    .footer-middle .recent-post-text > h4 {
        margin-top: -7px;
        line-height: 1;
    }
    .em-blog-content-area {
        padding: 22px 15px 7px;
    }
    .curosel-style .owl-nav div {
        left: -15px;
    }
    .curosel-style .owl-nav .owl-next {
        right: -15px;
    }
    span.cdowns {
        margin: 0 28px;
    }
    .footer-middle .mc4wp-form-fields button {
        width: 85px;
    }
    .footer-bottom:before {
        width: 100%;
    }
    .recent-post-image {
        margin-bottom: 20px;
    }
    .footer-middle .recent-post-text h4 a {
        font-size: 13px;
    }
    #sgardenollUp {
        bottom: 60px;
        right: 30px;
    }
    .witr_blog_con.bs5 h2 a {
        font-size: 22px;
    }

    .garden_project_area {
        padding: 95px 0px 172px;
    }
    .garden_portfolio_area .witr_section_title_inner p {
        width: 92%;
    }
    .garden_portfolio_area .witr_section_title_inner p {
        width: 85% !important;
    }
    /* faq area css */
    .faq_content_inner {
        margin-left: 0;
    }
    /* team area css */
    .witr_team_s12 {
        padding: 20px 20px 0;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .all_header_abs {
        position: inherit;
    }
    .witr_swiper_height {
        height: 500px;
    }
    .text-center .witr_swiper_content h1,
    .text-center .witr_swiper_content h2,
    .text-center .witr_swiper_content h3,
    .text-center .witr_swiper_content p,
    .text-left .witr_swiper_content h1,
    .text-left .witr_swiper_content h2,
    .text-left .witr_swiper_content h3,
    .text-left .witr_swiper_content p,
    .text-right .witr_swiper_content h1,
    .text-right .witr_swiper_content h2,
    .text-right .witr_swiper_content h3,
    .text-right .witr_swiper_content p {
        width: 100% !important;
        margin-left: auto;
        margin-right: auto;
        text-align: center !important;
    }
    .text-center .witr_swiper_content h2,
    .text-center .witr_swiper_content h3,
    .text-left .witr_swiper_content h2,
    .text-left .witr_swiper_content h3,
    .text-right .witr_swiper_content h2,
    .text-right .witr_swiper_content h3 {
        font-size: 40px;
    }
    .witr_section_title_inner h1,
    .witr_section_title_inner h3 {
        font-size: 28px;
    }
    .text-left .witr_btn_sinner,
    .text-right .witr_btn_sinner {
        text-align: center !important;
    }

    .all_header_abs .foft-header-top {
        background: #0049ff;
    }
    .foft-header-top {
        padding-right: 0 !important;
        padding-left: 0 !important;
    }
    .gardeneative_header_address {
        padding-left: 10px;
    }
    .tutor-course-loop-title h2 a {
        font-size: 17px;
    }
    .top_gardent_style {
        padding-bottom: 0;
    }
    .tx_top_together {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
    .gardeneative_header_curve {
        display: none;
    }
    .gardeneative_header_logo {
        padding-right: 0;
        text-align: center;
    }
    .em_gardeneative_header {
        background: transparent none repeat sgardenoll 0 0;
        z-index: 999;
    }
    .gardeneative_header_button {
        padding-bottom: 30px;
        text-align: center;
    }
    .gardeneative_header_button:before,
    .em_gardeneative_header:after,
    .em_gardeneative_header:before {
        display: none;
    }
    .em_gardeneative_header {
        padding-bottom: 0;
    }
    .foft-accourdion-title h2 i {
        display: none;
    }
    .accordion-content {
        padding: 23px 0;
    }
    .foft-accourdion-title h2 {
        padding-left: 20px;
    }
    .digital-item ul li a {
        padding: 7px 57px;
    }
    .mobil-item {
        margin-top: 30px;
    }
    .download-demo {
        text-align: center;
    }
    .digital-img img,
    .witr_app_area .digital-content h3 {
        margin-top: 0 !important;
    }
    .shortcode_content h2 {
        font-size: 35px !important;
    }
    /* testimonial area css */
    .witr_shape_item_inner {
        top: -70px;
    }

    /* single service area css */
    .single_service_area .witr_text_widget_inner h2 {
        font-size: 36px;
    }
    /* counter area css */
    .witr_counter_img {
        float: inherit;
        text-align: center;
        margin: 0 10px 20px 0;
    }
    .witr_counter_number_inn {
        text-align: center;
    }
    /* about area css */
    .cr_about_content_inner {
        padding: 0;
    }
    .about_list_image_inner {
        margin-top: 30px;
        margin-left: 0px;
    }
    .garden_about_image_inner .single_image img {
        margin-left: 0;
    }
    .garden_about_content_inner {
        padding: 0px 0px 0 0;
    }
    .garden_about_content_inner .headh1_style {
        padding-top: 0;
        padding-bottom: 0;
    }
    .about_list_image_inner {
        display: inherit;
    }
    .about_list_image_inner .single_image img {
        margin-right: 0;
        margin-top: 20px;
    }
    .about_content_inner {
        padding: 0;
    }
    .about_content_inner .witr_s_flex {
        display: inherit;
    }
    .about_content_inner .em-service-icon img {
        margin-bottom: 20px;
    }
    /* call action area css */
    .call_action_inner {
        margin-left: 0;
        margin-right: 0;
    }
    .foft_content h2,
    .foft_content h3 {
        font-size: 26px;
    }
    .foft_content h3 {
        font-size: 30px;
    }
    .cal_to_toggole {
        display: inherit;
        justify-content: center;
        padding: 60px 0px 30px 6px;
    }
    .foft_button.no_margin.text-right {
        text-align: center !important;
        margin-top: 30px;
    }
    .call_action_inner {
        padding: 85px 20px 60px 20px;
    }
    /* contact area css */
    .contact_area .apartment_text h2 {
        font-size: 30px;
    }
    .garden_contact_area .witr_section_title_inner p {
        width: 100% !important;
    }
    /* choose area css */
    .garden_choose_area .garden_about_content_inner {
        padding: 0;
        margin-left: -15px;
    }
    .garden_choose_list_inner {
        display: inherit;
        padding-right: 0;
    }
    .garden_choose_area .witr_widget_list {
        margin-bottom: 25px;
    }
    .garden_choose_area .witr_btn {
        margin-bottom: 50px;
    }
    /* project area css */
    .garden_portfolio_area .witr_section_title_inner p {
        width: 100% !important;
    }
    .positi_3.pprotfolio4 {
        padding: 10px 10px 10px;
    }

    .garden_project_area .witr_section_title_inner p {
        width: 80%;
    }
    .garden_project_area {
        padding: 95px 0px 172px;
    }
    .choose_content_inner .witr_section_title_inner p {
        width: 100%;
    }
    .choose_middle_content .witr_section_title_inner p {
        padding-right: 0;
    }
    /* tab area css */
    .witr_adv_tab_menu {
        margin: 0 -5px;
    }
    .tab-content {
        margin: 0px 0px 0px 10px;
    }
    .tab_content_inner {
        padding: 0;
    }
    .tab_image_inner .single_image {
        margin-left: 0;
        margin-top: 30px;
    }
    .nav-tabs {
        justify-content: inherit;
    }
    .tab_inner {
        padding: 50px 0px 30px 25px;
        margin-top: 25px;
        margin-left: -20px;
        margin-right: -20px;
    }
    .tab_inner2 .tab_content_inner {
        padding: 5px 0px 0px 0px;
        margin-left: 0px;
    }
    /* skill area css */
    .skill_inner {
        padding: 20px 15px 70px 20px;
    }
    /* testimonial area css */
    .em_single_testimonial {
        padding: 60px 45px 25px 45px;
    }
    .em_testi_title h2 {
        font-size: 20px;
    }

    .witr_testi_itemt {
        padding: 0 0px;
    }
    .all_color_testimonial h6,
    .all_color_testimonial h2 {
        margin: 5px 0 0;
    }
    /* blog area css */
    .single_blog_thumb img {
        height: 250px;
    }
    .comments-area {
        margin-left: -30px;
        margin-right: -10px;
    }
    .single_blog.single_blog_grid.singles {
        margin-left: -30px;
        margin-right: -10px;
    }
    .blog-left-side.widget {
        margin-right: -30px;
    }
    .title_btn a {
        padding: 18px 40px;
    }
    .garden-single-blog-details-inner {
        padding: 38px 30px 45px;
    }
    .garden-single-icon-inner a {
        margin-bottom: 5px;
    }
    .witr_blog_con.bs5 h2 a {
        font-size: 22px;
    }
    .footer-item form label {
        margin-left: 30px;
    }
    .footer-bottom:before {
        width: 100%;
    }
    .blog-content span {
        font-size: 14px;
    }
    .blog-left-side .widget h2 {
        font-size: 19px;
    }
    .blog-page-title h2 {
        font-size: 18px;
    }
    .widget .widget_media_image img {
        width: 100%;
    }
    .blog-left-side .blog-title > ul > li > a {
        height: 30px;
        width: 30px;
        line-height: 29px;
        font-size: 15px;
        margin-right: 2px;
    }
    .recent-post-text > h4 {
        line-height: 23px;
    }
    .recent-post-text .rcomment,
    .recent-post-text h4 a {
        font-size: 18px;
    }
    /* counter area css */
    .witr_counter_single {
        margin-bottom: 40px;
    }
    /* contact area css */
    .witr_text_widget_inner h1,
    .witr_text_widget_inner h2 {
        font-size: 26px;
    }
    .garden_contact_area .headh1_style {
        padding-top: 16px;
        font-size: 18px;
    }
    .apartment_text h2 {
        font-size: 25px;
    }
    .apartment_area {
        margin-right: 0;
    }
    .breadcumb-area {
        padding: 100px 0 !important;
    }
    .em-blog-content-area {
        padding: 15px 15px 10px;
    }
    .witr_blog_area10 .witr_blog_con {
        padding-top: 12px;
    }
    .portfolio-details-box {
        margin-left: 0;
    }
    .pimgs img {
        width: 100%;
    }
    .witr_btn {
        margin-top: 11px !important;
        margin-bottom: 15px;
    }
    .garden_about_area .witr_btn {
        margin-top: 35px !important;
    }
    .curosel-style .owl-nav div {
        left: -15px;
    }
    .curosel-style .owl-nav .owl-next {
        right: -15px;
    }
    .foft_event_thumb {
        float: none;
        margin-bottom: 15px;
    }
    .foft_single_event {
        margin: 0;
    }

    .foft_event_icon span {
        margin-right: 5px;
    }

    .recent-post-text {
        margin-top: 10px;
    }
    .em_slider_social {
        top: 41%;
        z-index: 999999;
        display: none;
    }
    #sgardenollUp {
        bottom: 60px;
        right: 30px;
    }
    /* service area css */
    .service_inner {
        margin: 0 -40px;
    }
    .service_inner .witr_s_flex {
        display: inherit;
    }
    .garden_service_area .em-service2.sleft .em-service-icon {
        margin-bottom: 20px;
    }
    /* team area css */
    .witr_team_section img {
        width: 100%;
    }
    /* faq area css */
    .faq_content_inner {
        margin-left: 0;
    }
    .faq_image_inner {
        padding: 0;
    }
    /* portfolio area css */
    .garden_portfolio_area .witr_section_title_inner p {
        width: 100%;
    }
}
/* ------------------------------------------------- Small Mobile : 320 ---------------------------------------------------*/
@media (max-width: 767px) {
    .single_header_address {
        padding: 0;
        padding-bottom: 25px;
    }
    .gardeneative_header_address {
        padding-left: 0;
    }
    .garden_top_addess.top-address p {
        text-align: center !important;
    }
    .breadcumb-area {
        padding: 90px 0 !important;
    }
    .brpt h2 {
        font-size: 30px;
    }
    .witr_btn {
        padding: 10px 26px;
        margin-right: 0;
    }
    .tutor-course-archive-filters-wrap {
        margin-top: 25px;
    }
    .top-address p a,
    .top-address p span,
    .top-right-menu ul.social-icons li a {
        font-size: 13px;
    }
    .social-icons {
        text-align: center;
    }
    .footer-top-inner p {
        width: 100%;
    }
    .footer-menu ul {
        margin: 10px 0 0;
        text-align: center !important;
    }
    .footer-menu ul li a {
        font-size: 14px;
    }
    .copy-right-text p {
        text-align: center;
    }
    .footer-bottom:before {
        width: 100%;
    }
    /* feature area css */
    .sub-border-2 .sub-item span {
        right: 6%;
    }
    .witr_s_flex {
        display: inherit;
    }
    .all_icon_color img {
        margin: 5px 40px 15px 0;
    }
    /* faq area css */
    .faq_image_inner {
        padding: 40px 0 0px 0px;
    }
    /* about area css */
    .garden_about_image_inner .single_image img {
        margin-left: 0;
        margin-bottom: 20px;
    }
    .garden_about_area .witr_btn {
        margin-top: 35px !important;
    }
    .about_list_image_inner {
        display: inherit;
    }
    .about_list_image_inner .single_image img {
        margin-right: 0;
        margin-top: 20px;
        width: 85%;
    }
    .about_content_inner {
        padding: 10px 0px 0 0;
    }
    .about_content_inner .witr_s_flex {
        display: inherit;
    }
    .about_list_image_inner {
        margin-left: 0;
    }
    /* project area css */
    .garden_portfolio_area {
        padding: 95px 0px 120px;
    }
    .garden_portfolio_area .witr_section_title_inner p {
        width: 100% !important;
    }
    .positi_3.pprotfolio4 {
        padding: 5px 10px 6px;
    }
    .garden_project_area {
        padding: 95px 0px 172px;
    }
    .garden_project_area .witr_section_title_inner p {
        width: 100%;
    }
    .choose_middle_content .witr_section_title_inner p {
        padding-right: 0;
    }
    .choose_content_inner .witr_section_title_inner p {
        width: 100%;
    }
    /* call action area css */
    .call_action_inner {
        padding: 75px 10px 70px;
    }
    .foft_content.text-left {
        text-align: center !important;
        margin-bottom: 20px;
    }
    .foft_content h2,
    .foft_content h3 {
        font-size: 22px;
    }
    .foft_content h3 {
        font-size: 22px;
    }
    .cal_to_toggole {
        display: inherit;
        justify-content: center;
        padding: 30px 0px 35px 6px;
    }
    .call_action_inner .mc4wp-form-fields button {
        width: 100%;
        position: inherit;
        padding: 17px 15px;
    }
    .call_action_inner .mc4wp-form-fields input {
        margin-bottom: 10px;
    }
    /* service area css */

    /* choose area css */
    .garden_choose_area .garden_about_content_inner {
        padding: 0;
    }
    .garden_choose_list_inner {
        display: inherit;
        padding-right: 0;
    }
    .garden_choose_area .witr_widget_list {
        margin-bottom: 25px;
    }
    .garden_choose_area .witr_btn {
        margin-bottom: 50px;
    }
    /* single service area css */
    .single_service_area .witr_text_widget_inner h2 {
        font-size: 24px;
    }
    /* tab area css */
    .tab_content_inner .witr_section_title_inner h2 {
        font-size: 18px;
    }
    .witr_adv_tab_menu {
        margin: 0px 50px 0px 50px;
    }
    .tab-content {
        margin: 0px 0px 0px 10px;
    }
    .tab_content_inner {
        padding: 0;
    }
    .tab_image_inner .single_image {
        margin-left: 0;
        margin-top: 30px;
    }
    .tab_inner2 .tab_content_inner {
        padding: 0px 0px 0px 0px;
        margin-left: 0px;
    }
    .tab_inner {
        padding: 50px 0px 30px 0px;
    }
    /* skill area css */
    .skill_inner {
        padding: 100px 15px 70px 25px;
    }
    .skill_image_inner {
        margin-top: 40px;
    }
    /* testimonial area css */
    .witr_testi_itemt {
        padding: 0;
    }
    .garden_testimonial_area {
        margin-top: 0px;
        padding: 115px 0 100px;
    }
    .em_single_testimonial {
        padding: 60px 20px 40px 20px;
    }
    .em_test_thumb {
        margin-right: 15px;
    }
    .execllent_star {
        margin-left: 5px;
    }
    .garden_testimonial_area2 .em_single_testimonial {
        padding: 55px 15px;
    }
    .em_testi_title h2 {
        font-size: 16px;
    }
    .all_color_testimonial h6,
    .all_color_testimonial h2 {
        margin: 4px 0 0;
    }
    .em_testi_title span {
        font-size: 14px;
        font-weight: 400;
    }
    .foft_button.no_margin.text-right {
        text-align: center !important;
        margin-top: 30px;
    }
    /* blog area css */
    .title_btn a {
        margin: 40px 0 0;
    }
    .single_blog_thumb img {
        height: 200px;
    }
    .garden-single-blog-details-inner {
        padding: 38px 30px 45px;
    }
    .garden-single-icon-inner a {
        margin-bottom: 5px;
    }
    .witr_blog_con h2 {
        margin: 15px 0 0;
    }
    .breadcumb-blog-area {
        padding: 50px 0;
    }
    .em-blog-content-area {
        padding: 20px 10px 5px;
    }
    .foft-blog-meta-left a,
    .foft-blog-meta-left span {
        font-size: 11px;
        margin-right: 3px;
    }
    .foft-blog-meta-right a,
    .foft-blog-meta-right span {
        font-size: 11px;
        margin-left: 3px;
    }
    .witr_ablog_content h2 a {
        font-size: 18px;
    }
    .blog-content ul,
    .post_reply ul,
    .single-blog-content ul {
        padding-left: 20px;
    }
    .blog-content ol,
    .post_reply ol,
    .single-blog-content ol {
        padding-left: 20px;
    }
    .comments-area .children {
        margin-left: 10px;
    }
    .comment_field .input-field {
        width: 100%;
    }
    .pdsr {
        padding-top: 50px;
    }
    .pdsl {
        padding-bottom: 90px;
    }
    #wp-calendar {
        display: block;
    }
    .copy-right-text p {
        font-size: 11px;
    }
    .footer-social-icon a i {
        font-size: 16px;
        height: 35px;
        line-height: 35px;
        width: 35px;
    }
    .gardeneative_header_curve {
        display: none;
    }
    .gardeneative_header_logo {
        padding-right: 0;
        text-align: center;
    }
    .em_gardeneative_header {
        background: transparent none repeat sgardenoll 0 0;
        z-index: 999;
    }
    .single_header_address {
        text-align: center;
    }
    .gardeneative_header_button {
        padding-bottom: 30px;
        position: relative;
        text-align: center;
    }
    .gardeneative_header_icon {
        float: none;
        margin-bottom: 10px;
        margin-right: 0;
        overflow: hidden;
    }
    .gardeneative_header_address .col-sm-4.col-xs-12 {
        padding-left: 0;
    }
    .gardeneative_header_logo {
        display: none;
    }
    .gardeneative_header_button:before,
    .em_gardeneative_header:after,
    .em_gardeneative_header:before {
        display: none;
    }
    /* team area css */
    .witr_team_s12 {
        padding-top: 0;
    }
    /* contact area css */
    .garden_contact_area .witr_section_title_inner p {
        width: 100% !important;
    }
    .witr_apartment_form .btn {
        width: 70%;
    }
    .witr_text_widget_inner h1,
    .witr_text_widget_inner h2 {
        font-size: 18px;
    }
    .garden_contact_area .headh1_style {
        padding-bottom: 0;
    }
    .garden_contact_area .witr_text_widget_inner h2 {
        margin-top: 15px;
    }
    .garden_contact_area .witr_text_widget_inner h2 {
        font-size: 23px;
    }
    .garden_contact_area .em-service-desc p {
        font-size: 18px;
    }
    .apartment_area {
        margin-left: 0;
        margin-right: 0;
        margin-top: 30px;
    }
    .apartment_text h2 {
        font-size: 25px;
    }
    .copy-right-text p {
        font-size: 15px;
    }
    .foft-blog-thumb img {
        width: 100%;
    }
    .digital-item ul li a {
        padding: 7px 27px;
    }
    .mobil-item {
        margin-top: 30px;
    }
    .elementor-tab-title {
        font-size: 13px !important;
    }
    .foft-accourdion-title h2 {
        font-size: 14px;
        padding-left: 20px;
    }
    .blog-img img {
        width: 100%;
    }
    .witr_blog_con {
        padding: 12px 10px 18px;
    }
    .witr_blog_con h2 {
        font-size: 21px;
    }
    .witr_post_Author {
        margin: 0 10px;
    }
    .recent-post-image {
        float: none;
    }
    .video-play-6 {
        width: 80%;
    }
    .subsgardenibe form input {
        width: 500px;
    }
    .download-content-6 ul li a {
        padding: 12px 46px 12px 80px;
    }
    .donwload-overlay {
        padding-top: 215px;
    }
    .digital-img {
        margin-top: 100px;
    }
    .tab_content.text-left {
        text-align: center !important;
    }
    .footer-middle {
        text-align: center;
    }
    .blog-content h5 > a {
        font-size: 17px;
    }
    .blog-content span {
        font-size: 12px;
    }
    .blog-page-title h2 {
        font-size: 17px;
    }
    .wblog-content h5 > a {
        font-size: 20px;
    }
    .wblog-content span {
        margin-right: 5px;
    }
    .blog-left-side .blog-title > ul > li > a {
        margin-right: 8px;
    }
    .widget .widget_media_image img {
        width: 100%;
    }
    .recent-post-text > h4 {
        margin-top: 2px;
        line-height: 1;
    }
    .recent-post-text h4 a {
        font-size: 14px;
    }
    .witr_blog_con6 h2 a {
        font-size: 19px;
    }
    .witr_blog_con6 h5 a {
        font-size: 15px;
    }
    .witr_blog_area8 .witr_blog_con6 p {
        display: none;
    }
    .witr_shortcode_inner {
        padding: 73px 15px 63px !important;
    }
    .shortcode_content h2 {
        font-size: 27px !important;
    }
    .mc4wp-form-fields button {
        width: 100%;
        position: inherit;
        padding: 18px 30px;
        margin-top: 25px;
    }
    .portfolio-details-box {
        margin-left: 0;
    }
    .portfolio-details-box ul li span.eright {
        font-size: 15px;
        margin-left: 0;
    }
    .portfolio-details-box ul li b {
        font-size: 15px;
    }
    .portfolio-details-box h2 {
        padding-top: 20px;
    }
    .portfolio-details-box .foft-single-icon-inner a {
        margin: 0 4px;
    }
    #sb_instagram .sbi_photo_wrap {
        margin-bottom: 25px;
    }
    .woocommerce div.product .woocommerce-tabs ul.tabs li a {
        padding: 0.5em 6px;
    }
    .woocommerce div.product .woocommerce-tabs ul.tabs li {
        padding: 0 0.5em;
        margin-right: 6px;
    }
    .foft-header-top {
        display: none;
    }
    .footer-sociala-icon {
        float: none;
    }
    .footer-sociala-info p {
        text-align: center;
    }
    .em_slider_social {
        top: 40%;
        display: none;
    }
    #sgardenollUp {
        bottom: 92px;
        right: 30px;
    }

    .woocommerce div.product form.cart .button {
        font-size: 13px;
    }
    .product_tl_nav ul li {
        margin: 0 3px 10px;
        padding: 7px 18px;
        font-size: 16px;
    }
    /* service area css */
    .service_inner {
        margin: 0 -15px;
    }
    /* counter area css */
    .witr_counter_single {
        margin-bottom: 41px;
        text-align: center;
    }
    .witr_counter_img {
        float: inherit;
        text-align: center;
        margin-bottom: 10px;
    }
    /* faq area css */
    .faq_content_inner {
        margin-left: 0;
        margin-bottom: 40px;
    }
    .faq_img_inner .single_image img {
        margin-left: 0;
        margin-bottom: 20px;
    }
    /* portfolio area css */
    .garden_portfolio_area .witr_section_title_inner p {
        width: 100%;
    }
}

@media only sgardeneen and (min-width: 480px) and (max-width: 767px) {
    .blog-part {
        text-align: center;
    }
    .foft-header-top {
        display: block;
    }
}
@media (min-width: 10px) and (max-width: 991px) {
    .mobile-menu {
        display: block !important;
        height: auto;
    }
    .mobile_logo_area {
        display: block !important;
    }
    .foft-main-menu {
        display: none !important;
    }
    .d_md_none {
        display: none !important;
    }
    .main_menu_pop_area.witr_h_h23 {
        display: none;
    }
}
@media (min-width: 10px) and (max-width: 991px) {
    .text_m_center {
        text-align: center !important;
    }
    .text_m_right {
        text-align: right !important;
    }
    .text_m_left {
        text-align: right !important;
    }
    .menu_18.top-address p span {
        font-size: 13px;
    }
    .footer-top-inner p {
        width: 85%;
    }
}
@media (min-width: 10px) and (max-width: 768px) {
    .ps_absulate {
        position: inherit !important;
    }
    .psc_absolute {
        position: inherit !important;
    }
}
@media (min-width: 570px) and (max-width: 767px) {
    .text_s_center {
        text-align: center !important;
    }
    .text_s_right {
        text-align: right !important;
    }
    .text_s_left {
        text-align: right !important;
    }
    .footer-top-inner p {
        width: 95%;
    }
}
@media (min-width: 10px) and (max-width: 570px) {
    .foft-header-top {
        display: none;
    }
}

.logo-text {
    font-size: 28px;
    text-transform: uppercase;
    font-weight: 600;
    width: 300px;
    line-height: 1;
    font-family: rockwell_bold !important;
    text-align: center;
    margin-left: -16px;
}

.logo_text_anim {
    background: linear-gradient(-45deg, #66fe38, #0c71c3, hsl(37, 96%, 48%), #91743e);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: back_gradient_anim 8s ease-in-out infinite;
    -webkit-animation: back_gradient_anim 8s ease-in-out infinite;
    background-size: 300%;
    color: transparent;
}

.logo-sub-text {
    font-family: rockwell_bold !important;
    font-size: 60px;
}
.main_logo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    width: max-content;
    cursor: pointer;

    .logo_img_main {
        display: block;
    }

    .logo_img_fixed {
        display: none;
    }
}

.my_profile_wrapper {
    // display: 'flex',
    // gap: mobileView ? '5px' : '20px',
    // position: 'relative',
    // alignItems: 'center',
    // justifyContent: 'center',

    display: flex;
    gap: 20px;
    position: relative;
    align-items: center;
    justify-content: center;

    .header_icons {
        color: #91743e;
    }

    .account_wrapper {
        display: flex;
        gap: 6px;
        align-items: center;
        cursor: pointer;
        color: #91743e;
        border: 1px solid #91743e;
        padding: 5px 15px;
        border-radius: 30px;
        position: relative;
    }

    .account_popover {
        position: absolute;
        top: 60px;
        right: -10px;
        width: 200px;
        border-radius: 10px;
        background-color: white;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        z-index: 999;
        overflow: hidden;

        .menu_items {
            height: 40px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            &:hover {
                background-color: #f5f5f5;
            }
        }
    }
}

.foft_nav_area.sticky {
    background-color: #91743e;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-in;
    z-index: 1000;
    position: fixed;
    top: 0px;
    margin-left: 0px;
    width: 100%;
    left: 0px;

    a {
        color: #fff;

        &:hover {
            color: #fff;
        }
    }

    .logo-text {
        color: #fff;
    }

    .logo_text_anim {
        background: linear-gradient(-45deg, #0c71c3, #66fe38, #fff, #fff);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        animation: back_gradient_anim 8s ease-in-out infinite;
        -webkit-animation: back_gradient_anim 8s ease-in-out infinite;
        background-size: 300%;
        color: transparent;
    }

    .logo_img_main {
        display: none;
    }

    .logo_img_fixed {
        display: block;
        -webkit-filter: invert(100%); /* Safari/Chrome */
        filter: invert(100%);
    }

    .my_profile_wrapper {
        .header_icons {
            color: #fff;
        }

        .account_wrapper {
            color: #fff;
            border: 1px solid #fff;
        }

        .account_popover {
            position: absolute;
            top: 60px;
            right: -10px;
            width: 200px;
            border-radius: 10px;
            background-color: white;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            z-index: 999;
            overflow: hidden;

            .menu_items {
                color: #91743e;
            }
        }
    }
}

.mobile-header {
    display: flex;
    flex-direction: column;
    background-color: #91743e;
    padding: 10px;
    box-shadow: 0 0 10px rgba(251, 255, 251, 1);

    &.sticky {
        background-color: #91743e;
        box-shadow: 0 0 10px rgba(251, 255, 251, 1);
        transition: all 0.3s ease-in;
        z-index: 1000;
        position: fixed;
        top: 0px;
        margin-left: 0px;
        width: 100%;
        left: 0px;
    }

    .mobile-header-top {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .mobile-logo {
            cursor: pointer;
            display: flex;
            gap: 10px;
            align-items: center;

            img {
                width: 80px;
                object-fit: cover;
            }

            .logo-text {
                font-size: 18px;
                text-transform: uppercase;
                font-weight: 600;
                width: auto;
                line-height: 1;
                font-family: rockwell_bold !important;
                text-align: center;
                margin-left: 6px;

                .logo-sub-text {
                    font-family: rockwell_bold !important;
                    font-size: 32px;
                }
            }

            .logo_text_anim {
                background: linear-gradient(-45deg, #66fe38, #6093bd, #fff, hsl(37, 96%, 48%));
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                animation: back_gradient_anim 8s ease-in-out infinite;
                -webkit-animation: back_gradient_anim 8s ease-in-out infinite;
                background-size: 300%;
                color: transparent;
            }
        }

        .mobile-icons {
            display: flex;
            gap: 10px;

            .header_icons {
                color: #fff;
                font-size: 30px;
                cursor: pointer;
            }
        }
    }

    .mobile-menu {
        display: flex;
        flex-direction: column;
        background-color: #fff;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        padding: 10px;
        margin-top: 10px;
        position: relative;

        .mobile-menu-close {
            font-size: 30px;
            font-weight: bold;
            text-align: right;
            cursor: pointer;
            // padding: 10px;
            position: absolute;
            right: 15px;
            top: 0px;
        }

        .sub-menu {
            list-style: none;
            padding: 0;
            margin: 0;

            li {
                margin-bottom: 10px;

                a {
                    color: #91743e;
                    font-size: 16px;
                    font-weight: 700;
                    text-decoration: none;
                    display: block;
                    padding: 10px;
                    border-radius: 5px;
                    transition: background-color 0.3s;

                    &:hover {
                        background-color: #f5f5f5;
                    }
                }

                &.menu-item-has-children {
                    position: relative;

                    .sub-menu {
                        display: none;
                        position: absolute;
                        left: 40%;
                        top: 0;
                        background-color: #fff;
                        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
                        padding: 10px;
                        margin-top: -10px;

                        li {
                            margin-bottom: 0;

                            a {
                                padding: 5px 10px;
                            }
                        }
                    }

                    &:hover > .sub-menu {
                        display: block;
                    }
                }
            }
        }
    }

    .account_popover {
        position: absolute;
        top: 60px;
        right: 10px;
        width: 200px;
        border-radius: 10px;
        background-color: white;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        z-index: 999;
        overflow: hidden;

        .menu_items {
            height: 40px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            &:hover {
                background-color: #f5f5f5;
            }
        }
    }
}
