@font-face {
    font-family: IcoFont;
    font-weight: 400;
    font-style: Regular;
    src:
        url(../../assets/fonts/icofont.woff2) format("woff2"),
        url(../../assets/fonts/icofont.woff) format("woff");
}
@import url("https://fonts.googleapis.com/css?family=DM+Sans:400,400i,500,500i,700,700i&display=swap");

@font-face {
    font-family: "lato";
    src: url("https://fonts.cdnfonts.com/css/lato");
    font-weight: 400;
    font-style: normal;
}

@import url("https://fonts.cdnfonts.com/css/lato");
body {
    font-family: var(--bs-body-font-family);
    font-size: var(--bs-body-font-size);
    font-weight: var(--bs-body-font-weight);
    line-height: var(--bs-body-line-height);
    color: var(--bs-body-color);
    text-align: var(--bs-body-text-align);
    font-style: normal;
}
body {
    /* CSS Variables that may have been missed get put on body */
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    --bs-link-hover-color-rgb: 10, 88, 202;
    --bs-link-color-rgb: var(--bs-link-hover-color-rgb);
}

* {
    box-sizing: border-box;
    font-family: "lato" !important;
}

body {
    margin: 0;
    font-family: var(--bs-body-font-family);
    font-size: var(--bs-body-font-size);
    font-weight: var(--bs-body-font-weight);
    line-height: var(--bs-body-line-height);
    color: var(--bs-body-color);
    text-align: var(--bs-body-text-align);
    background-color: var(--bs-body-bg);
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
}

body {
    margin: 0;
}

body {
    overflow-x: hidden;
}

body {
    font-family: "lato" !important;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    line-height: 1.6;
    color: #4f555a;
}

html {
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
}

html {
    overflow-x: hidden;
}

:root {
    --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans",
        Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --bs-body-font-family: var(--bs-font-sans-serif);
    --bs-body-font-size: 1rem;
    --bs-body-font-weight: 400;
    --bs-body-line-height: 1.5;
    --bs-body-color: #212529;
    --bs-body-bg: #fff;
    --bs-link-color-rgb: 13, 110, 253;
    --bs-link-hover-color-rgb: 10, 88, 202;
    --bs-border-radius-xxl: 2rem;
    --bs-border-radius-2xl: var(--bs-border-radius-xxl);
}

// video section css start

.video_section {
    .youtube_embed_holder {
        width: 100%;
        overflow: hidden;
        aspect-ratio: 16/6;
        pointer-events: none;

        iframe {
            width: 300%;
            height: 100%;
            margin-left: -100%;
            // margin-top: -10%;
        }
    }
}

// carousal css start

.slider {
    width: 100%;
    height: 75vh;
    max-height: 600px;
}

.slider-1 {
    background:
        // linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
        // url("https://livedemo00.template-help.com/wt_51647/images/slider-4-slide-1-1920x678.jpg");
        url("https://firebasestorage.googleapis.com/v0/b/foft-prod.appspot.com/o/carousal%2Fmain.png?alt=media&token=d480d5f2-5aa5-428b-ba6b-3708ea097762");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.slider-2 {
    background:
        linear-gradient(rgba(4, 4, 4, 0.3), rgba(0, 0, 0, 0.3)),
        // url("https://livedemo00.template-help.com/wt_51647/images/slider-4-slide-1-1920x678.jpg");
        url("https://firebasestorage.googleapis.com/v0/b/foft-prod.appspot.com/o/carousal%2Foral_care.png?alt=media&token=5088726f-863e-43c9-b8d0-6a72860047f1");
    background-repeat: no-repeat;
    // background-size: cover;
    background-position: center;
}

.slider-3 {
    background:
        linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
        // url("https://livedemo00.template-help.com/wt_51647/images/slider-4-slide-2-1920x678.jpg");
        url("https://firebasestorage.googleapis.com/v0/b/foft-prod.appspot.com/o/carousal%2Fhair_care.png?alt=media&token=7ce97c44-5609-42eb-9558-c022831621a9");
    background-repeat: no-repeat;
    // background-size: cover;
    background-position: center;
}

.slider-4 {
    background:
        linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
        // url("https://livedemo00.template-help.com/wt_51647/images/slider-4-slide-3-1920x678.jpg");
        url("https://firebasestorage.googleapis.com/v0/b/foft-prod.appspot.com/o/carousal%2Fdrink_water.png?alt=media&token=65ee96bf-8305-4b9d-995f-b85a25165170");
    background-repeat: no-repeat;
    // background-size: cover;
    background-position: center;
}

.slider-5 {
    background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
        url("https://firebasestorage.googleapis.com/v0/b/foft-prod.appspot.com/o/carousal%2Fkitchen_cutlary.png?alt=media&token=4e80a39a-5ca2-4b36-954f-3a90346bc181");
    background-repeat: no-repeat;
    // background-size: cover;
    background-position: center;
}

.slider-6 {
    background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
        url("https://firebasestorage.googleapis.com/v0/b/foft-prod.appspot.com/o/carousal%2Fpersonal_care.png?alt=media&token=57dd6a1f-0f98-4c84-9a44-a56743b46d50");
    background-repeat: no-repeat;
    // background-size: cover;
    background-position: center;
}

@media screen and (max-width: 768px) {
    .slider {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }
    .slider-1 {
        background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
            url("https://firebasestorage.googleapis.com/v0/b/foft-prod.appspot.com/o/carousal%2Fmain_mobile.png?alt=media&token=183c33d1-7728-48aa-ac28-5f939caf46f1");
    }
    .slider-2 {
        background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
            url("https://firebasestorage.googleapis.com/v0/b/foft-prod.appspot.com/o/carousal%2Foral_care_mobile.png?alt=media&token=ccb8a6f2-3171-4faa-9cc7-97c42c3e9a16");
    }
    .slider-3 {
        background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
            url("https://firebasestorage.googleapis.com/v0/b/foft-prod.appspot.com/o/carousal%2Fhair_care_mobile.png?alt=media&token=a4610395-3a77-493f-a98a-a01ec8e9c55f");
    }
    .slider-4 {
        background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
            url("https://firebasestorage.googleapis.com/v0/b/foft-prod.appspot.com/o/carousal%2Fdrink_water_mobile.png?alt=media&token=9427b8e4-b1e1-46d7-8613-5143a5520be1");
    }
    .slider-5 {
        background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
            url("https://firebasestorage.googleapis.com/v0/b/foft-prod.appspot.com/o/carousal%2Fkitchen_cutlary_mobile.png?alt=media&token=91e4ab61-4f61-4c29-b22b-598109c3493f");
    }
    .slider-6 {
        background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
            url("https://firebasestorage.googleapis.com/v0/b/foft-prod.appspot.com/o/carousal%2Fpersonal_care_mobile.png?alt=media&token=44d99755-37fe-4e03-933c-c6cbfe2a8b5a");
    }
}

.slider-1,
.slider-2,
.slider-3,
.slider-4,
.slider-5,
.slider-6 {
    background-size: cover;
    // animation: slider_zoom 5s infinite ease-in-out;
}

@keyframes slider_zoom {
    0% {
        background-size: 110vw 80vh;
    }
    100% {
        background-size: 100vw 75vh;
    }
}

.slider-text {
    color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}

.slider-text h1 {
    font-size: 3rem;
}

.slider-text h4 {
    font-size: 2rem;
    text-align: center;
}

.slider .shop_now_btn {
    background: #91743e;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    text-transform: uppercase;
    font-weight: 600;
    margin-top: 20px;

    width: max-content;
    position: absolute;
    bottom: 4rem;
    right: 4rem;
    width: 150px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;

    a {
        color: #fff;
    }

    &:hover {
        background: transparent !important;
        color: #91743e !important;
        border: 4px solid #91743e !important;
        a {
            color: #91743e !important;
        }
    }
}

.slider .shop_now_btn:hover {
    background: transparent !important;
    color: #91743e !important;
    border: 4px solid #91743e !important;
    a {
        color: #91743e !important;
    }
}

.slick-dots li button:before {
    color: #91743e;
    font-size: 16px;
}

.slick-dots li.slick-active button:before {
    opacity: 1;
    color: #91743e;
    font-size: 25px;
}

// show case numbers css start

.showcase_numbers_wrapper {
    margin-top: 50px;

    @media (prefers-reduced-motion: no-preference) {
        :root {
            scroll-behavior: smooth;
        }
    }

    .garden_counter_area {
        padding: 100px 0 65px;
    }

    *,
    :after,
    :before {
        box-sizing: border-box;
    }

    .container {
        --bs-gutter-x: 1.5rem;
        --bs-gutter-y: 0;
        width: 100%;
        padding-right: calc(var(--bs-gutter-x) * 0.5);
        padding-left: calc(var(--bs-gutter-x) * 0.5);
        margin-right: auto;
        margin-left: auto;
    }

    @media (min-width: 576px) {
        .container {
            max-width: 540px;
        }
    }

    @media (min-width: 768px) {
        .container {
            max-width: 720px;
        }
    }

    @media (min-width: 992px) {
        .container {
            max-width: 960px;
        }
    }

    @media (min-width: 1200px) {
        .container {
            max-width: 1140px;
        }
    }

    @media (min-width: 1400px) {
        .container {
            max-width: 1320px;
        }
    }

    .row {
        --bs-gutter-x: 1.5rem;
        --bs-gutter-y: 0;
        display: flex;
        flex-wrap: wrap;
        margin-top: calc(-1 * var(--bs-gutter-y));
        margin-right: calc(-0.5 * var(--bs-gutter-x));
        margin-left: calc(-0.5 * var(--bs-gutter-x));
    }

    .row > * {
        flex-shrink: 0;
        width: 100%;
        max-width: 100%;
        padding-right: calc(var(--bs-gutter-x) * 0.5);
        padding-left: calc(var(--bs-gutter-x) * 0.5);
        margin-top: var(--bs-gutter-y);
    }

    @media (min-width: 768px) {
        .col-md-6 {
            flex: 0 0 auto;
            width: 50%;
        }
    }

    @media (min-width: 992px) {
        .col-lg-3 {
            flex: 0 0 auto;
            width: 25%;
        }
    }

    .witr_counter_single {
        text-align: left;
        margin-bottom: 30px;
    }

    .witr_counter_img {
        margin: 0 23px 0 0;
        float: left;
    }

    .witr_counter_number_inn {
        overflow: hidden;
    }

    img {
        vertical-align: middle;
    }

    img {
        border-style: none;
    }

    img {
        max-width: 100%;
        height: auto;
    }

    h3 {
        margin-top: 0;
        margin-bottom: 0.5rem;
        font-weight: 500;
        line-height: 1.2;
        color: var(--bs-heading-color);
    }

    h3 {
        font-size: calc(1.3rem + 0.6vw);
    }

    @media (min-width: 1200px) {
        h3 {
            font-size: 1.75rem;
        }
    }

    h3 {
        font-family: "Lexend Deca", sans-serif;
        font-style: normal;
        line-height: 1.2;
        font-weight: 600;
        -webkit-transition: 0.5s;
        transition: 0.5s;
        font-size: 22px;
        margin-bottom: 5px;
        color: #232323;
    }

    .witr_counter_number_inn h3 {
        font-size: 40px;
        display: inline-block;
        font-weight: 700;
        margin-bottom: 0;
    }

    .witr_counter_number_inn h3 {
        color: #91743e;
    }

    .witr_counter_number_inn span {
        font-size: 40px;
        display: inline-block;
        font-weight: 700;
        margin-bottom: 0;
    }

    .witr_counter_number_inn span {
        color: #91743e;
    }

    .witr_counter_number_inn span {
        line-height: 0;
    }

    h4 {
        margin-top: 0;
        margin-bottom: 0.5rem;
        font-weight: 500;
        line-height: 1.2;
        color: var(--bs-heading-color);
    }

    h4 {
        font-size: calc(1.275rem + 0.3vw);
    }

    @media (min-width: 1200px) {
        h4 {
            font-size: 1.5rem;
        }
    }

    h4 {
        font-family: "Lexend Deca", sans-serif;
        font-style: normal;
        line-height: 1.2;
        font-weight: 600;
        -webkit-transition: 0.5s;
        transition: 0.5s;
        font-size: 22px;
        margin-bottom: 5px;
        color: #232323;
    }

    .all_counter_color h4 {
        font-family: "lato";
        font-size: 21px;
        font-weight: 700;
        margin: 10px 0 0;
    }
}

// revies section css start

.reviews_wrapper {
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 5rem;
    padding-right: 5rem;
    background-image: url("../../assets//images/new_bg.png");

    .reviews_slider {
        .slick-arrow {
            &::before {
                color: #91743e;
                font-size: 40px;
            }
        }
        .slick-list {
            overflow: hidden;
            .slick-track {
                display: flex;
                gap: 50px;
            }
        }
    }

    .review_box_wrapper {
        margin-top: 50px;
        margin-bottom: 30px;
        transition: all 0.5s ease 0s;
        padding: 50px 40px;
        overflow: hidden;
        position: relative;
        text-align: left;
        box-shadow: -23px 25px 5px -4px rgba(51.99999999999999, 142, 56.00000000000002, 0.18823529411764706);
        background: #fff;

        width: 50%;

        display: flex !important;
        flex-direction: row;
        justify-content: flex-start;
        // align-items: flex-start;
        gap: 20px;
        border-radius: 15px;
        .review_img {
            img {
                width: 200px;
                height: 200px;
                border-radius: 20px;
            }
        }

        .review_details {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;

            .stars_wrapper {
            }

            .review_text {
                font-size: 16px;
                font-weight: 400;
                line-height: 1.5;
                color: #232323;
                margin-top: 20px;
                // show ellipsis if text is too long
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
            }

            .person_details {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-start;

                .person_name {
                    font-size: 20px;
                    font-weight: 700;
                    color: #232323;
                    margin-top: 20px;
                }
                .person_position {
                    color: #91743e;
                    display: block;
                    margin-top: 0px;
                    font-size: 14px;
                    font-weight: 400;
                }
            }
        }
    }
}

// Blogs
.home_blogs_container {
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 5rem;
    padding-right: 5rem;

    .blogs_slider {
        .slick-arrow {
            &::before {
                color: #91743e;
                font-size: 40px;
            }
        }
        .slick-list {
            overflow: hidden;
            .slick-track {
                display: flex;
                gap: 50px;

                .slick-slide {
                    min-height: 100% !important;
                    display: flex !important;
                    height: inherit;
                }
            }
        }
    }

    .blog_box {
        // margin-top: 50px;
        // // margin-bottom: 30px;
        // transition: all 0.5s ease 0s;
        // // padding: 50px 40px;
        // overflow: hidden;
        // position: relative;
        // text-align: left;
        // // box-shadow: -23px 25px 5px -4px rgba(51.99999999999999, 142, 56.00000000000002, 0.18823529411764706);
        // background: #fff;
        // // border: 1px solid #e5e5e5;

        // width: 50%;

        // display: flex !important;
        // flex-direction: column;
        // justify-content: flex-start;
        // // align-items: flex-start;
        // gap: 20px;
        // border-radius: 15px;

        // box-shadow:
        //     0 4px 8px 0 rgba(0, 0, 0, 0.2),
        //     0 6px 20px 0 rgba(0, 0, 0, 0.19);
        // margin-bottom: 15px;

        // height: 88%;
        // padding-bottom: 30px;

        margin-top: 50px;
        transition: all 0.5s ease 0s;
        overflow: hidden;
        position: relative;
        text-align: left;
        background: rgba(145, 116, 62, 0.7); // #91743e; //#fff;
        width: 50%;
        display: flex !important;
        flex-direction: column;
        justify-content: flex-start;
        gap: 20px;
        border-radius: 15px;
        box-shadow:
            0 4px 8px 0 rgba(0, 0, 0, 0.2),
            0 6px 20px 0 rgba(0, 0, 0, 0.19);
        margin-bottom: 15px;
        height: -webkit-fill-available;
        margin-bottom: 30px;

        .blog_image {
            position: relative;
            img {
                width: 100%;
                height: 250px;
                border-radius: 15px 15px 0 0;
            }
            .blog_author {
                position: absolute;
                bottom: 0;
                right: 0;
                background: rgba(15, 15, 15, 0.7); // #6d4e15db;
                color: #fff;
                padding: 7px;
                .author_position {
                    font-size: 14px;
                    font-weight: 400;
                    color: #fff;
                    width: 100%;
                    float: right;
                    text-align: right;
                    font-style: italic;
                }
            }
        }
        .blog_content {
            // padding: 0 15px 15px;
            padding: 0 15px 15px;
            display: flex;
            flex-direction: column;
            justify-content: stretch;
            height: 100%;
            position: relative;
            color: #ffffff;
        }
        .blog_footer {
            // display: flex;
            // justify-content: flex-end;
            // align-items: center;

            // display: flex;
            // justify-content: flex-end;
            // align-items: center;
            // position: absolute;
            // bottom: 15px;
            // right: 15px;

            // display: flex;
            // justify-content: flex-end;
            // align-items: center;
            // position: absolute;
            // bottom: -15px;
            // right: 15px;

            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding-right: 15px;
            padding-bottom: 15px;
            /* bottom: -15px; */
            position: relative;

            .read_more {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: -moz-fit-content;
                background: rgba(15, 15, 15, 0.7); //#91743e;
                color: #fff;
                padding: 4px 8px 8px;
                border-radius: 8px;
                cursor: pointer;
                gap: 4px;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .home_blogs_container {
        padding-top: 50px;
        padding-bottom: 50px;
        padding-left: 1.5rem;
        padding-right: 3rem;

        .blogs_slider {
            .slick-list {
                .slick-track {
                    display: flex;
                    gap: 20px;
                }
            }
        }
    }
}

// End

@media (max-width: 768px) {
    .reviews_wrapper {
        padding-left: 1rem;
        padding-right: 1rem;

        .review_box_wrapper {
            width: 100%;
            flex-direction: column;
            align-items: center;
            text-align: center;
            padding: 20px;

            .review_img {
                img {
                    width: 150px;
                    height: 150px;
                }
            }

            .review_details {
                align-items: center;

                .review_text {
                    align-items: center;
                }
            }
        }
    }

    .reviews_wrapper .reviews_slider .slick-list .slick-track {
        display: flex;
        gap: 20px;
    }
}

// conservation_mission_section css start

.conservation_mission_section {
}

// footer css start

.footer_wrapper {
    position: relative;

    .footer-address {
        .footer_s_inner {
            display: flex;
        }

        @media screen and (max-width: 768px) {
            .footer_s_inner {
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }

    @media (prefers-reduced-motion: no-preference) {
        :root {
            scroll-behavior: smooth;
        }
    }

    .footer-middle {
        background-color: #91743e;
        padding: 10px 0 45px;
    }

    .container {
        --bs-gutter-x: 1.5rem;
        --bs-gutter-y: 0;
        width: 100%;
        padding-right: calc(var(--bs-gutter-x) * 0.5);
        padding-left: calc(var(--bs-gutter-x) * 0.5);
        margin-right: auto;
        margin-left: auto;
    }

    @media (min-width: 576px) {
        .container {
            max-width: 540px;
        }
    }

    @media (min-width: 768px) {
        .container {
            max-width: 720px;
        }
    }

    @media (min-width: 992px) {
        .container {
            max-width: 960px;
        }
    }

    @media (min-width: 1200px) {
        .container {
            max-width: 1140px;
        }
    }

    @media (min-width: 1400px) {
        .container {
            max-width: 1320px;
        }
    }

    .row {
        --bs-gutter-x: 1.5rem;
        --bs-gutter-y: 0;
        display: flex;
        flex-wrap: wrap;
        margin-top: calc(-1 * var(--bs-gutter-y));
        margin-right: calc(-0.5 * var(--bs-gutter-x));
        margin-left: calc(-0.5 * var(--bs-gutter-x));
    }

    .row > * {
        flex-shrink: 0;
        width: 100%;
        max-width: 100%;
        padding-right: calc(var(--bs-gutter-x) * 0.5);
        padding-left: calc(var(--bs-gutter-x) * 0.5);
        margin-top: var(--bs-gutter-y);
    }

    @media (min-width: 576px) {
        .col-sm-12 {
            flex: 0 0 auto;
            width: 100%;
        }
    }

    @media (min-width: 768px) {
        .col-md-6 {
            flex: 0 0 auto;
            width: 50%;
        }
    }

    @media (min-width: 992px) {
        .col-lg-3 {
            flex: 0 0 auto;
            width: 25%;
        }
    }

    h2 {
        margin-top: 0;
        margin-bottom: 0.5rem;
        font-weight: 500;
        line-height: 1.2;
        color: var(--bs-heading-color);
    }

    h2 {
        font-size: calc(1.325rem + 0.9vw);
    }

    @media (min-width: 1200px) {
        h2 {
            font-size: 2rem;
        }
    }

    h2 {
        font-family: "Lexend Deca", sans-serif;
        font-style: normal;
        line-height: 1.2;
        font-weight: 600;
        -webkit-transition: 0.5s;
        transition: 0.5s;
        font-size: 22px;
        margin-bottom: 5px;
        color: #232323;
    }

    .footer-middle .widget h2 {
        color: #ffffff;
    }

    .footer-middle .widget h2 {
        margin-bottom: 28px;
        margin-top: 28px;
        position: relative;
    }

    a {
        color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
        text-decoration: underline;
    }

    a {
        background-color: transparent;
    }

    a {
        font-style: normal;
        font-weight: 400;
    }

    a {
        text-decoration: none;
    }

    a {
        color: #91743e;
        transition: 0.5s;
    }

    a:hover {
        --bs-link-color-rgb: var(--bs-link-hover-color-rgb);
    }

    a:hover {
        text-decoration: none;
        color: #91743e;
    }

    p {
        margin-top: 0;
        margin-bottom: 1rem;
    }

    p {
        font-style: normal;
    }

    .foft-description-area p,
    .footer-middle .foft-description-area p {
        color: #ffffff;
    }

    ul {
        padding-left: 2rem;
    }

    ul {
        margin-top: 0;
        margin-bottom: 1rem;
    }

    ul {
        margin: 0;
        padding: 0;
    }

    ul {
        font-style: normal;
        font-weight: 400;
    }

    .footer-widget.address {
        color: #ffffff;
    }

    figure {
        margin: 0 0 1rem;
    }

    .gallery-item {
        display: inline-block;
        padding: 1.79104477%;
        text-align: center;
        vertical-align: top;
        width: 100%;
        margin: 0;
    }

    .gallery-columns-3 .gallery-item {
        max-width: 32%;
    }

    img {
        vertical-align: middle;
    }

    img {
        border-style: none;
    }

    img {
        max-width: 100%;
        height: auto;
        border-radius: 8px;
    }

    .foft-description-area a img {
        margin: 26px 0;
    }

    .foft-description-area .phone a {
        color: #ffffff;
    }

    .foft-description-area .social-icons a {
        color: #ffffff;
    }

    .foft-description-area .social-icons a {
        width: 40px;
        height: 40px;
        line-height: 40px;
        display: inline-block;
        text-align: center;
        border-radius: 4px;
        margin-right: 5px;
        margin-bottom: 5px;
        background: #1f1d1d;
    }

    .foft-description-area .social-icons a:hover {
        background: #91743e;
        border-color: #91743e;
    }

    li {
        list-style: none;
    }

    li {
        font-style: normal;
        font-weight: 400;
    }

    .footer-middle .widget ul li {
        color: #ffffff;
    }

    .footer-middle .widget ul li {
        margin-bottom: 10px;
    }

    .footer-middle .widget ul li,
    .footer-middle .widget ul li a,
    .footer-middle .widget ul li::before,
    .footer-middle .tagcloud a,
    .footer-middle caption,
    .footer-middle table,
    .footer-middle table td a,
    .footer-middle cite,
    .footer-middle .rssSummary,
    .footer-middle span.rss-date,
    .footer-middle span.comment-author-link,
    .footer-middle .textwidget p,
    .footer-middle .widget .screen-reader-text,
    mc4wp-form-fields p,
    .mc4wp-form-fields,
    .footer-m-address p,
    .footer-m-address,
    .footer-widget.address,
    .footer-widget.address p,
    .mc4wp-form-fields p,
    .foft-description-area p,
    .foft-description-area .phone a,
    .foft-description-area .social-icons a,
    .recent-review-content h3,
    .recent-review-content h3 a,
    .recent-review-content p,
    .footer-middle .foft-description-area p,
    .footer-middle .recent-post-text h4 a,
    .footer-middle .recent-post-text .rcomment,
    .witr_sub_table span {
        color: #ffffff;
    }

    .footer-middle .widget ul li a:hover,
    .footer-middle .widget ul li:hover::before {
        // color: #91743e;
        opacity: 0.5;
    }

    .footer-middle .widget ul li,
    .footer-middle .widget ul li a,
    .footer-middle .widget ul li::before,
    .footer-middle .tagcloud a,
    .footer-middle caption,
    .footer-middle table,
    .footer-middle table td a,
    .footer-middle cite,
    .footer-middle .rssSummary,
    .footer-middle span.rss-date,
    .footer-middle span.comment-author-link,
    .footer-middle .textwidget p,
    .footer-middle .widget .screen-reader-text,
    mc4wp-form-fields p,
    .mc4wp-form-fields,
    .footer-m-address p,
    .footer-m-address,
    .footer-widget.address,
    .footer-widget.address p,
    .mc4wp-form-fields p,
    .foft-description-area p,
    .foft-description-area .phone a,
    .foft-description-area .social-icons a,
    .recent-review-content h3,
    .recent-review-content h3 a,
    .recent-review-content p,
    .footer-middle .foft-description-area p,
    .footer-middle .recent-post-text h4 a,
    .footer-middle .recent-post-text .rcomment,
    .witr_sub_table span {
        color: #ffffff;
    }

    i {
        font-style: normal;
        font-weight: 400;
    }

    [class^="icofont-"] {
        font-family: IcoFont !important;
        speak: none;
        font-style: normal;
        font-weight: 400;
        font-variant: normal;
        text-transform: none;
        white-space: nowrap;
        word-wrap: normal;
        direction: ltr;
        line-height: 1;
        -webkit-font-feature-settings: "liga";
        -webkit-font-smoothing: antialiased;
    }

    .icofont-facebook:before {
        content: "\ed37";
    }

    .icofont-x:before {
        content: "\f03d";
    }

    .icofont-pinterest:before {
        content: "\ed5d";
    }

    .footer-sociala-icon {
        clear: both;
        float: left;
        font-size: 17px;
        margin-right: 12px;
    }

    @media screen and (max-width: 768px) {
        .footer-sociala-icon {
            clear: both;
            float: left;
            font-size: 17px;
            margin-right: 12px;
            margin-top: -1rem;
        }
    }

    .icofont-google-map:before {
        content: "\ed42";
    }

    .icofont-phone:before {
        content: "\efbb";
    }

    .icofont-envelope-open:before {
        content: "\ef13";
    }

    .maintain_by_wrapper {
        padding: 10px 0 45px;
        color: #fff;

        position: absolute;
        bottom: 0;
        right: 4rem;
        z-index: 99;
        width: max-content;
        text-align: center;

        a {
            color: #fff;
        }

        .company_name {
            color: aqua;
        }
    }

    @media screen and (max-width: 768px) {
        .maintain_by_wrapper {
            padding: 10px;
            position: relative;
            bottom: 0;
            right: 0;
            z-index: 99;
            width: auto;
            text-align: center;
            margin-top: 20px;

            .company_name {
                display: inline-block;
            }
        }
    }
}

// respnsive carousel css start for mobile

@media (max-width: 768px) {
    .slider-text {
        h1 {
            font-size: 1.5rem;
        }

        h4 {
            font-size: 1rem;
        }
    }

    .slider .shop_now_btn {
        bottom: 2rem;
        right: 2rem;
        width: 120px;
        height: 40px;
        font-size: 0.8rem;
    }

    .review_box_wrapper {
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: 20px;

        .review_img {
            img {
                width: 150px;
                height: 150px;
            }
        }

        .review_details {
            align-items: center;

            .review_text {
                -webkit-line-clamp: 5;
            }
        }
    }
}
